p {
  margin: 0;
}
.task-contain {
  /* padding: 16px 1px 1px 1px; */
  width: 30%;
  /* padding: 18px 0px; */
}
.task_note {
  color: #879096;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
}
.task-status-input-text {
  color: var(--second-text-color, #67677f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.head-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  align-items: center;
}
.head-with-arrow {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.owner-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.task-select-style {
  width: 140px;
  font-size: 14px;
  padding: 0px;
}
.full-select-style {
  /* width: 140px; */
  font-size: 13px;
  padding: 0px;
}

.head-txt {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sub-txt {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.arow {
  cursor: pointer;
}
.sub-head {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.8;
}

.desc-txt {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.7;
  margin-bottom: 10px;
}

.owner-select-contain {
  margin-bottom: 10px;
}

.status-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;
}
.edit-txt {
  text-decoration: underline;
  font-size: 14px;
  opacity: 0.8;
}
.round {
  height: 6px;
  width: 6px;
  border-radius: 100px;
  background-color: var(--bg-light-gray);
}
.status-wrapper {
  display: flex;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 0.5px solid var(--border-color);
}
.status-txt {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-new {
  display: flex;
  gap: 3px;
  align-items: center;
  /* background: #f2f2f2; */
  flex-direction: row;
  /* display: block; */
  cursor: pointer;
  margin-top: 15px;
}

.group-sub-head {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
  margin-top: 12px;
  margin-bottom: 8px;
}
.card-head-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
}
.card-contain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.card {
  border-radius: 4px;
  border: 0.5px solid #3454d1;
  background: var(--bg-primary);
  /* border: 1px solid #3f3939; */
  padding: 8px 10px 11px 10px;
  /* border-left-width: 4px; */
}
.card-other {
  border: 1px solid var(--border-color);
  padding: 8px 10px 11px 10px;
  /* border-left-width: 4px; */
  border-radius: 4px;
  margin-bottom: 15px;
}

.line {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
  margin-top: 10px;
}

.used-txt {
  font-size: 13px;
  opacity: 0.7;
  font-weight: 400;
  margin-top: 6px;
}

.organization-layout {
  padding-top: 15px;
}

.organization_list {
  padding: 10px 15px 10px 0px;
}
.org-nav-contain {
  display: flex;
  gap: 10px;
}

.department_list {
  padding: 10px 15px 10px 0px;
}

.sticky-div {
  width: 57%;
  position: sticky;
  bottom: 15px;
  background: var(--bg-primary);
  height: 41px;
  padding: 10px;
  z-index: 10;
  /* height: 85vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sett-save-btn {
  border: none;
  padding: 4px 12px;
  border-radius: 8px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: #fff;
  margin-left: 68%;
  position: sticky;
  bottom: 21px;
}

.add-status {
  min-height: 25vh;
  width: 25vw;
  background: var(--bg-primary);
  border-radius: 2px;
  margin-top: 50px;
  max-height: 70vh;
}
.status-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.status-container {
  display: block;
  padding: 15px 20px 15px 20px;
}

.status-head-txt {
  font-size: 16px;
  font-weight: 400;
}
.status-inp {
  width: 100%;
  height: 36px;
  border-radius: 2px;
  border: 1px solid #d1d1d1;
  outline: none;
  padding-left: 5px;
  font-size: 15px;
}
.status-check-wrap {
  display: flex;
  gap: 10px;
  margin-top: 13px;
  margin-bottom: 25px;
  align-items: center;
}
.check-bx-txt {
  font-size: 13px;
  opacity: 0.8;
}

.status-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.status-color {
  position: relative;
  height: 29px;
  width: 70px;
  background: red;
  overflow: hidden;
  border-radius: 2px;
}

.add-status {
  font-size: 17px;
  font-weight: 600;
}

.status-error {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: red;
}

.edit-dele-wrap {
  display: flex;
  gap: 10px;
}

.add-status-group {
  width: 30vw;
}

.group-head-txt {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.head-close-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}
.group-head {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.group-section {
  padding: 1px 20px 20px 20px;
}

.group-color {
  position: relative;
  min-height: 20px;
  min-width: 20px;
  background: red;
  overflow: hidden;
  border-radius: 200px;
  /* margin-bottom: 10px; */
}

.status-non-inp {
  border: none;
  outline: none;
  padding: 8px 2px 8px 5px;
  width: 100%;
}
.status-wrap {
  position: relative;
  display: flex;
  gap: 10px;
  border: 1px solid #d2d2d2;
  align-items: center;
  padding: 0px 2px 0px 11px;
  margin-right: 5px;
  width: 100%;
  border-radius: 8px;
}

.priority-wrap {
  position: relative;
  display: flex;
  gap: 10px;
  /* border: 1px solid #d2d2d2; */
  align-items: center;
  /* padding: 0px 2px 0px 11px; */
  margin-right: 5px;
  width: 100%;
}
.dl-contain {
  padding: 1px 9px;
}

.group-save-cancel {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.pos-default {
  position: absolute;
  top: -10px;
  /* left: 32px; */
  left: 38px;
  background: var(--bg-primary);
  padding: 0px 5px;
  font-size: 13px;
  opacity: 0.7;
}

.status-list {
  padding-top: 10px;
  min-height: 20vh;
  max-height: 46vh;
  /* background: red; */
  overflow: auto;
  overflow-x: hidden;
  /* scrollbar-width: thin; */
}

.status-error {
  font-size: 13px;
  font-weight: 400;
  color: red;
  margin-top: 2px;
}
.status-drag-contain {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

#menu:checked ~ .menu-content {
  display: block;
}

.menu-content {
  height: 100px;
  width: 150px;
  background: red;
  position: absolute;
  right: -180px;
  display: none;
}

.status-delete {
  min-height: 1vh;
  max-height: 70vh;
  overflow-y: scroll;
  max-width: 450px;
}
.modal_footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.status-del-contain {
  padding: 10px 10px 5px 10px;
}
.delete-icon-wrapper {
  display: flex;
  justify-content: center;
  margin: 11px 0px 11px 0px;
}

.del-description {
  text-align: center;
  padding: 0 20px 20px 20px;
}

.error-txt {
  font-size: 12px;
  color: red;
  margin-top: 3px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
}

.loading-absolute-right {
  position: absolute;
  right: 25px;
  top: 70px;
}

.edit-dele-wrap .options {
  display: none;
  /* additional styling for options if needed */
}

.edit-dele-wrap input[type='radio']:checked + label + .options {
  display: block;
}

.options {
  position: absolute;
  min-height: 25px;
  background: var(--bg-light-gray);
  width: 100px;
  /* top: 5px; */
  /* left: 45px; */
  border-radius: 6px;
  z-index: 5;
  /* right: 0%; */
  left: 25px;
  padding: 10px 5px;
}
.option-line {
  height: 1px;
  background-color: #d2d2d2;
  margin: 5px 0px 5px 0px;
}

.option {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.additional-save-btn {
  border: none;
  padding: 4px 28px;
  border-radius: 2px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: var(--bg-primary);
  /* margin-left: 90%; */
}

.menu-tab {
  padding: 0 10px;
  margin-bottom: 10px;
  font-weight: 500;
  width: 180px;
  color: var(--btn-text-color);
  font-size: 14px;
  height: 26px;
  outline: none;
  border: none;
  background: var(--bg-light-gray);
}

.menu-tab:hover {
  background: var(--btn-hover);
}
.menu-tab:focus {
  background: var(--btn-hover);
}

.permission-title {
  color: var(--main-text-color);
  font-weight: 500;
}

.permission-note {
  color: var(--second-text-color);
}

.permission-note:hover {
  background-color: inherit;
  color: var(--second-text-color);
}

.permission-body:hover {
  background-color: inherit;
}
.permission-checkbox {
  border-radius: 5px;
}

.permission-checkbox:checked {
  accent-color: var(--accent-color);
}
.company-table-container {
  max-width: 1100px;
  /* padding: 16px 24px; */
  border-radius: 4px;
}
.layout-head {
  width: 158px;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.org-add-button-container {
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
  gap: 10px;
}

.org-add-container {
  margin: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
}
.sections {
  overflow: scroll;
  height: 80vh;
  width: 100%;
  margin: 30px 0px;
}
.settings_tab {
  height: 27px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  padding: 0px 8px;
  transition: all 0.3s ease-in-out;
  width: 129px;
  text-decoration: none;
  cursor: pointer;
  justify-content: space-between;
}

.cursor_no_drop_settings_tab {
  height: 27px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  transition: all 0.3s ease-in-out;
  width: 129px;
  text-decoration: none;
  cursor: no-drop;
  justify-content: space-between;
}

.settings_tab_active {
  background: var(--bg-light-gray);
  text-decoration: none;
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.settings_tab_inactive {
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(--bg-primary);
}
.settings_tab_inactive:hover {
  background: var(--bg-light-gray) !important;
  color: var(--second-text-color);
}

.description-text {
  color: var(--second-text-color);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}

.settings-text-input {
  border-radius: 8px;

  width: 318px;
  height: 30px;
}

.settings-more-menu {
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--bg-primary);
}

.team-list-table {
  width: 552px;
}

.task_tabs_container {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 18px;
}
.settings-toggler {
  display: none;
}
.settings-toggler.rotate-left {
  top: 11px;
  transform: translateY(-50%) rotate(0deg);
}
.settings-toggler.rotate-right {
  transform: translateY(-50%) rotate(180deg);
}

.settings-toggle-container {
  display: none;
  position: sticky;
  top: 56px;
  left: 0px;
  align-items: center;
  padding: 10px 16px;
}
.my-team-form {
  display: flex;
  gap: 1.25rem;
}
.my-team-reported-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 20px;
}
.permission-group {
  borderbottom: 1px solid var(--border-color);
  width: 80vw;
}
@media (max-width: 768px) {
  .sub_padding-settings {
    position: relative;
    /* margin: 56px 16px; */
    top: 35px;
  }
  .settings-toggle-container {
    display: flex;
    z-index: 10;
  }
  .settings-toggler {
    display: inline-block;
    position: relative;
    top: 11px;
    left: -2px;
    height: 23px;
    width: 20px;
    transition: transform 0.5s ease-in-out;
  }
  .head-visible {
    transform: translateX(0);
  }
  .head-none {
    transform: translateX(-140%);
    /* width: 0px; */
  }
  .layout-head {
    width: 125px;
  }

  .layout-head.head-none {
    width: 0;
    opacity: 0;
    overflow: hidden;
  }

  /* Expand content when settings menu is hidden */
  .sections {
    transition: width 0.3s ease-in-out;
    flex-grow: 1;
  }

  .sections.expand-part {
    width: calc(100% - 250px); /* Full width when menu is visible */
  }

  .sections.full-width {
    width: 100%; /* Full width when menu is hidden */
  }
  .sub_container {
    width: 100%;
  }
  .task-contain {
    width: 100%;
  }
  .add-status-group {
    width: 80vw;
  }
  .add-status {
    width: 80vw;
  }
  /*  for create custom field modal in settings */
  .emp-modal_view {
    width: 100vw;
  }
  .emp-input {
    width: 100%;
  }
  .close-absolute {
    top: 7px;
    right: 6px;
  }
  /*  for my team responsive in settings */
  .team-list-table {
    width: 100%;
    padding: 0px;
    margin: 0;
  }
  .table_outer_team {
    border-radius: 4px;
    border: 1px solid var(--border-color);
    background: var(--bg-primary);
    overflow: scroll;
    margin-top: 145px;
  }

  .modal_view_branch {
    width: 92vw;
  }
  .status-delete {
    max-width: 80vw;
  }
  .organization_add_form_container {
    height: 50vh;
    overflow: auto;
  }

  /* organization settings list  */
  .company_table_header .tab_contain {
    top: -10px;
    /* left: 0%; */
    position: absolute;
    width: 88%;
    box-shadow: none;
    border-bottom: 1px solid var(--border-color);
  }
  .company-table-container .table-container {
    margin-top: 95px;
    width: 89%;
    overflow: scroll;
    padding: 0px;
  }
  .organization_add_form_container {
    height: 60vh;
    overflow: auto;
  }
  .sections .my-task-tabs {
    width: 80vw;
    top: 10px;
  }
  .sections .tab_contain {
    top: 10px;
    width: 80vw;
    position: sticky;
  }
  .modal_view_label {
    width: 80vw;
  }
  .sections .myTeam-table-container {
    width: 80vw;
    padding: 0px;
    top: 14px;
  }
  .sections .table-container {
    margin-top: 65px;
    padding: 35px 0px;
    max-height: 65vh !important;
    width: 80vw;
  }
  .section .organization_list .table_outer_team {
    margin-top: 26px;
  }
  /* my team  */
  .my-team-form {
    display: flex;
    flex-direction: column;
  }
  .my-team-reported-container {
    padding: 0px;
  }
  .sections .menu_popup {
    right: -60px;
  }
  .user-select-none {
    justify-content: flex-start !important;
  }
  .section .department_list {
    padding: 0px;
    margin: 0px;
  }
  .section .my-team-list .tab_contain {
    top: 15px !important;
  }
  /* theme */

  .code_text {
    width: 80vw;
  }
  .sett-save-btn {
    margin-left: 58%;
  }

  /* label */
  .modal-view-trigger {
    width: auto;
  }
}

/* invoice */
.invoice-sub {
  font-size: 13px;
  margin-bottom: 8px;
}
.invoice-head {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 20px;
}

.invoice-btn {
  border: none;
  padding: 4px 12px;
  border-radius: 8px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: #fff;
}
.inv-btn-container {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f8f8f8; */
}

.invoice-container {
  font-family: Arial, sans-serif;

  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* table css */
.inv-table-container {
  /* border: 1px solid #ddd; */
  border-radius: var(--border-radius);
  overflow: hidden;
  /* max-width: 800px; */
  /* margin: 20px auto; */
  background-color: #fff;
  /* font-family: Arial, sans-serif; */


}

.inv-table-header,
.inv-table-row {
  display: flex;
  justify-content: space-between;
  /* padding: 15px 20px; */
  border-bottom: 1px solid #ddd;
  padding: 3px 3px 5px 0px;
}

.inv-table-header {
  font-weight: bold;
  background: #f1f3f3;
  padding: 6px 0px 6px 0px;
}

.inv-table-row .inv-table-column {
  padding: 5px 0px 5px 0px;
}

.inv-table-footer {
  padding: 5px 0px 5px 0px;
  /* margin-top: 10px; */
  display: flex;
}
.inv-table-column {
  flex: 1;
  text-align: center;
}

/* .inv-table-footer {
  font-weight: bold;
} */

/* .inv-table-footer.total {
  border-top: 1px solid #000;
} */

.inv-footer-label {
  flex: 2;
  text-align: right;
  padding-right: 20px;
}

.inv-footer-value {
  flex: 1;
  text-align: right;
}

.head-bold {
  font-weight: 600;
  font-size: 14px;
}

/* invoice */
