.icon-outer-file {
  border-radius: 100px;
  padding: 7px;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.list-type-selection-box {
  width: fit-content;
  height: 32px;
  border: 1px solid rgb(190, 188, 188);
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  padding-left: 10px;
}

.list-type-selection-box:hover {
  border: 1px solid rgb(13, 12, 12);
}

.folder-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* repeat(auto-fill, 2fr); */
  gap: 16px;
  text-align: left;
}

.description-editor {
  font-size: 13px;
  font-weight: 400px;
}

.details-header-modal {
  font-size: 22px;
  font-weight: 500;
}

/* folder list view css  */
.list-view-row {
  display: flex;
  align-items: center;
  max-width: 70%;
  padding: 5px 10px;
  text-align: left;
}

.list-view-name {
  width: 50%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
  position: relative;
}

.tooltip-file {
  position: fixed;
  background-color: rgb(91, 90, 90);
  padding: 4px 8px;
  color: var(--bg-primary);
  font-size: 11px;
  white-space: nowrap;
  z-index: 10;
}

.name-text {
  display: inline-block;
  max-width: 100%;
}

.list-view-row:hover {
  background-color: rgb(230, 229, 229);
}

.folder-view-name {
  margin: -12px 10px 0px 10px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* file preview  */

.preview-head {
  font-size: 22px;
  color: var(--bg-primary);
  padding: 0 15px;
}

.preview-files {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.preview-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.preview-modal-contain {
  height: 100vh;
  width: 100vw;
  background: rgba(15, 15, 15, 0.9);
  position: fixed;
  z-index: 5;
}
.preview-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-pdf canvas {
  /* width: 80vw !important; Adjust the width as needed */
  height: 87vh !important; /* Adjust the height as needed */
  max-width: 100%;
  max-height: 100%;
}

.customStyleSelection {
  min-height: 10px;
  border: none;
  font-size: 13px;
  outline: none;
  margin-right: 5px;
}

.customStyleSelection option {
  padding: 50px;
}
.file-table-container {
  border-radius: 4px 4px 0px 0px;
  max-width: 80vw;
  margin: 20px;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary);
}

.file-tab-line {
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  position: absolute;
  top: 50px;
  z-index: 0;
}

.file-tab-contain {
  display: flex;
  gap: 18px;
  padding: 15px 15px 0 15px;
}
.file_tabs {
  position: relative;
}

.empty-head {
  color: var(--main-text-color);

  font-size: 13px;
  font-weight: 500;
}

.empty-text {
  color: var(--second-text-color);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-clr {
  color: var(--main-text-color, #2a2e34);
}
.empty-outer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 13px 3px 8px 0px;
  padding: 3rem 5px 3rem 5px;
}
