.cardTitle {
  font-size: 20px;
  font-weight: 500;
}

.card_Container {
  font-size: 13px;
  width: fit-content;
  border: none !important;
}

.inputbox {
  width: 320px;
  height: 34px;
  border-radius: 3px;
  border: 1px solid var(--Base-Color, #ç);
  background: var(--bg-primary);
  font-size: 13px;
}
.verification_inputbox {
  width: 34px;
  height: 34px;
  border-radius: 3px;
  border: 1px solid var(--Base-Color, #3454d1);
  background: var(--bg-primary);
  font-size: 13px;
}

.login_Button {
  width: 320px;
  height: 34px;
  color: var(--bg-primary);
  font-size: 13px;
  border-radius: 3px;
  background-color: #3454d1;
  border-color: #3454d1;
}

.login_Button:hover {
  color: #6599ff;
}
.conatiner {
  height: 100%;
  width: 100%;
}
.center {
  margin: 0 auto;
  width: 300px;
  height: 40px;
}
.image {
  width: 100%;
  position: relative;
}
.ab {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 8px;
  padding-right: 1px;
}
.input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 5px;
  border-radius: 2px;
}

.remember_box_container {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.remember_box {
  width: 13px;
  height: 13px;
}

.remember_box:checked {
  accent-color: black;
}

.head_text {
  font-size: 28px;
  font-weight: 600;
}
.head_text2 {
  font-size: 16px;
  font-weight: 400;
}

.login_header_contain {
  display: flex;
  min-height: 50px;
  height: var(--top-bar-height);
  justify-content: space-between;
  align-items: center;
  padding: var(--login-top-bar-padding);
  border-bottom: 1px solid var(--border-color);
}

.login_body_container {
  height: calc(100vh - 80px);
  align-items: center;
  display: flex;
  justify-content: center;
}

.login_company_icon_contain {
  /* border-bottom: 1px solid var(--border-color);
  padding: var(--app-logo-left-padding);
  height: var(--top-bar-height);
  position: sticky;
  top: 0px; */
  border-bottom: 1px solid var(--border-color);
  /* padding: var(--app-logo-left-padding); */
  height: var(--top-bar-height);
  position: sticky;
  top: 0px;
  /* background: var(--sidebar-bg); */
  height: 50px;
  flex-wrap: wrap;
  width: 200px;
}

.login_company_icon_contain img {
  object-fit: contain;
  width: 150px;
  height: 50px;
}

.login_img_container {
  display: flex;
  width: 465.2px;
  height: 410.27px;
  padding: 0.001px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.login-welcome-txt {
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 138%; /* 33.12px */
}
.remember_txt {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.forgot-txt {
  color: #1e3db9;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
}

.forgot-txt:hover {
  text-decoration: underline;
}

.error-txt {
  font-size: 12px;
  color: red;
  margin-top: 3px;
}

.forgot_second_txt {
  color: var(--3rd-text-color, #656f7d);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 19.5px */
  width: 276px;
}

.login_label {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 768px) {
  .login_left_side {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login_body_container {
    height: auto;
  }
  .login_img_container {
    display: none;
  }
  .login_right_side {
    display: none;
  }
  .login_header_contain {
    display: contents;
  }
  .login_company_icon_contain {
    padding: 20px 24px;
  }
}
