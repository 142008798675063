/* .dashboard_menu_active {
  font-weight: 500;
  font-size: 14px;
  color: black !important;
  text-decoration: none;
  border-bottom: 1.8px solid black;
  padding: 0px 2px 2px 2px;
}
.dashboard_menu_inactive {
  font-weight: 400;
  font-size: 14px;
  color: black;
  text-decoration: none;
  padding: 0px 2px 2px 2px;
  opacity: 0.5;
} */

.dashboard_menu_active {
  padding: 6px 1px 6px 1px;
  border-bottom: 1px solid #434343;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
}
.dashboard_menu_inactive {
  padding: 1px 1px 9px 1px;
  gap: 5px;
  /* border-bottom: 1.6px solid red; */
  font-size: 13px;
  font-weight: 500;
  opacity: 0.5;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dashboard-layout {
  /* padding-top: 5px; */
  background: var(--bg-primary);
  padding: 0px 24px;
}
.dashboard-upper-container {
  /* height: 62vh; */
  scroll-snap-align: start;
}
.sub_container_dashboard {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background: var(--bg-primary);
}
.sub_container_dashboard .chat_list_condainer {
  height: auto !important;
}
/* .sub_container_dashboard .chat_list_condainer .chat-tabs {
  display: none !important;
} */
.sub_container_dashboard_content {
  /* flex: 1 1; */
  /* height: 70vh; */
  /* overflow-y: scroll; */
  overflow: hidden;
  min-height: 20vh;
}
@media screen and (max-width: 768px) {
  .sub_container_dashboard_content {
    overflow-y: scroll;
  }
}

.sub_container_dashboard_content:hover {
  /* height: calc(55vh - 52px) !important; */
  /* height: calc(100% - 10px) !important; */
  scrollbar-gutter: stable;
  scrollbar-width: none;
  overflow-y: scroll;
}

.dashboard-chat-container .sub_container_dashboard_content {
  /* height: calc(55vh - 52px) !important; */
}
.dashboard-container {
  padding: var(--top-breadcrumb-padding);
}
@media (max-width: 768px) {
  .sub_container_dashboard_content {
    height: auto !important;
  }
  .dashboard-container {
    padding: var(--top-breadcrumb-padding-responsive);
  }
  .notification-card {
    margin: 30px 16px !important;
  }
  .sub_container_dashboard_content:hover {
    /* height: calc(55vh - 52px) !important; */
    height: calc(100% - 50px) !important;
  }
}
/* .sub_container_dashboard .header-border-bottom {
  display: none !important;
} */
.recent-container {
  max-height: 180px;
  border-bottom: 2px solid var(--border-color);
  margin: 1px;
}

.dashboard-msg-count {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  background-color: var(--text-dark);
  color: var(--bg-primary);
  font-size: 9px;
  font-weight: 500;
  display: grid;
  place-items: center;
}

.task-name {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
}
.task-description {
  font-size: 13px;
  font-weight: 300;
  margin-left: 0px;
}

.task-count-box {
  height: 71px;
  padding: 15px 11px;
  border-radius: 3px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary);

  /* width: 162px;
  height: 71px;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary); */
}

.task-pointer {
  height: 12px;
  width: 12px;
  border-radius: 3px;
}

.dashboard_heading {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid var(--divider, #f0f0f0);
  padding-bottom: 8px;
  padding-top: 3px;
}

.dashboard_count_icon_bg {
  width: 40px;
  height: 40px;
  background-color: #4786ff;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  font-size: 11px;
}

.dashboard_count_txt {
  font-size: 13px;
  font-weight: 500;

  color: var(--second-text-color, #87909e);
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  line-height: normal;
  white-space: nowrap;
}

.dashboard_count {
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard_view_all {
  color: var(--3rd-text-color, #656f7d);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chat_name {
  font-size: 13px;
  font-weight: 600;
}

.chat_time {
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.chat_count_container {
  width: 16px;
  height: 16px;
  background-color: #3454d1;
  color: var(--bg-primary);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: 600;
}

.dashboard-status-txt {
  font-size: 14px;
  font-weight: 600;
}
.task-count {
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.task-percentage {
  font-size: 13px;
  font-weight: 500;
}

.dashboard-chat-container {
  --update-padding: 22px;
  border-radius: 4px;
}

.dashboard-update-container {
  height: calc(60vh - 20px) !important;
  width: 23vw !important;
}

/* ---------------------------- */
/* StatusUpdateCard.css */

.status-card {
  display: grid;
  grid-template-columns: 45px auto;
  gap: 3px;
  align-items: start;
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 8px 4px;
}
.status-card:hover {
  background-color: var(--border-color);
}

.update-profile-wrapper {
  display: flex;
}

.document-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

/* .document-icon img {
  width: 24px;
  height: 24px;
} */

.status-content {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 2px;
}

.main-text {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  max-height: 3em; /* 2 lines of text */
  overflow: hidden;
  word-break: break-word;
  font-weight: 600;
  max-width: 88%;
}

.update-message {
  font-size: 13px;
  color: #656f7d;
  font-weight: 400;
  margin: 0;
  line-height: 1.5;
  max-height: 3em; /* 2 lines of text */
  overflow: hidden;
  word-break: break-word;
}

.message-content {
  display: flex;
  /* align-items: center; */
  gap: 5px;
}

.chat-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e0f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-icon img {
  width: 16px;
  height: 16px;
}

.message-text {
  font-size: 13px;
  color: #656f7d;
  font-weight: 500;
}

.user-name {
  font-weight: bold;
}

.badge {
  background-color: #fcedf0;
  color: #b00404;
  border-radius: 15%;
  padding: 2px 6px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timestamp {
  color: var(--second-text-color);
  font-size: 12px;
  font-weight: 500;
}

.notification-card {
  margin: 0px 24px;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary);
  padding: 9px 5px;
}

.notification-unread-button-container {
  /* margin: 0px 30px; */
  margin: 8px 0px 12px 24px;
  /* margin: 0px 0px 12px 30px; */
}

/* ----------------- */
/* notification item */

.nt-status-content {
  display: flex;
  grid-template-rows: auto auto;
  gap: 0.25rem;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.nt-status-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px;
  gap: 0.25rem;
  padding: 8px 4px;
  border-radius: var(--border-radius);
  /* display: grid;
  grid-template-columns: 45px auto;
  gap: 3px;
  align-items: start;
  background-color: var(--bg-primary);
  border-radius: 8px;
  margin-bottom: 1.3em; */
}

.nt-status-card:hover {
  background-color: var(--border-color);
}

.nt-message-content {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  padding: 0px 7px 0px 0px;
}

.nt-message-text {
  font-size: 13px;
  color: #656f7d;
  font-weight: 500;
}

.nt-update-message {
  font-size: 13px;
  color: var(--second-text-color);
  font-weight: 400;
  margin: 0;
  line-height: 1.5;
  max-height: 3em;
  overflow: hidden;
  word-break: break-word;
}

.nt-timestamp {
  color: var(--second-text-color);
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}
.text-bold {
  font-weight: bold;
}

.nt-chat-icon {
  height: 18px;
  width: 18px;
  /* background: #eaf7f3; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  /* padding: 1px; */
  /* align-content: center; */
  align-items: center;
}
.nt-icon-container {
  display: flex;
  gap: 3px;
  align-items: flex-start;
}

/* notification item end */

.dashboard-widget {
  /* margin: 10px; */
  box-sizing: border-box;
  min-height: 150px; /* Set a minimum height for the widgets */
}

/* Flex size adjustments */
.size-1 {
  flex: 1 1 33.33% !important; /* 1/3 of the row */
}

.size-2 {
  flex: 2 1 66.66% !important; /* 2/3 of the row */
}

.size-3 {
  flex: 3 1 100% !important; /* Full row */
}

.dashboard-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
}

.dashboard-widget-header {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Always limit to 3 columns */
  gap: 10px;
  grid-auto-flow: row;
}

.item1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.item2 {
  grid-column: 3;
  grid-row: 1 / span 2;
}

.item5 {
  grid-column: 1 / span 3;
  grid-row: 3;
}
.grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-top: 10px;
}
.grid-item {
  border: 1px solid #ddd;
  background-color: var(--bg-primary);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary);
}

/*  */
/* dashboard.css */

.dashboard-container {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa; /* or whatever background suits your app */
}

.layout {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.grid-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  background-color: var(--bg-primary);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.dashboard-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  cursor: move; /* Draggable handle cursor */
}

.dashboard-widget-header {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
}

.menu-item-outline {
  padding: 5px 3px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.menu-item-outline:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Ensuring widgets take full height if resized */
.react-grid-item {
  display: flex;
  flex-direction: column;
}

.updates_container {
  display: flex;
  overflow: auto;
  margin-bottom: 50px;
  width: 100%;
  scrollbar-gutter: stable;
}

/* Handle responsive behavior on smaller screens */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 5px;
  }

  .dashboard-widget-header {
    font-size: 0.9rem;
  }

  .grid-item {
    padding: 10px;
  }
}
