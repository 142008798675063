.okr-tooltip-container {
  position: relative;
}

#MODAL .pop-over-item-container .okr-tooltip-text {
  display: block !important;
}

#MODAL .pop-over-item-container .triangle {
  display: block !important;
}

.okr-tooltip-container:hover .okr-tooltip-text,
.okr-tooltip-container:hover .triangle {
  display: block;
}
.okr-tooltip-text {
  display: none;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(42, 46, 52, 1);
  font-size: 0.625rem;
  color: var(--bg-primary);
  padding: 4px 7px;
  top: calc(100% + 12px);
  border-radius: 4px;
  z-index: 99999;
}

.okr-tooltip-text::after {
  content: '';
}

.triangle {
  display: none;
  z-index: 99;
  position: absolute;
  background-color: rgb(42, 46, 52);
  left: 50%;
  translate: -50%;
  top: calc(100% + 9px);
}
.triangle:before,
.triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
  width: 10px;
  height: 10px;
  border-top-right-radius: 30%;
}

.triangle {
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}
.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
