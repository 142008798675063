/* .css-b62m3t-container {
  width: 200px;
} */

.placeholder {
  color: #879096;
  font-size: 12px;
}
.priority-label {
  white-space: nowrap;
  border-radius: 9px;
  width: fit-content;
  padding: 3px 10px 3px 10px;
  background-color: var(--bg-light);
  color: var(--btn-text-light);
  font-weight: 500;
  font-size: 11px;
  line-height: normal;
  display: inline-flex;
  white-space: nowrap;
}

.time-related-container {
  min-width: 170px;
  top: calc(100% + 5px);
  background: var(--bg-primary);
  position: absolute;
  z-index: 100;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.underline-hover:hover {
  text-decoration: underline;
}
.task-add-container {
  min-height: 55vh;
  /* max-width: none; */
  min-width: 30vw;
  background: var(--bg-primary);
  border-radius: 10px;
  margin-top: 50px;
}
.task-add-head {
  display: none;
}
.task-add-description-container {
  border-radius: 5px;
  /* border: 1px solid #f5f8fc; */
}
.select-box {
  min-height: 35px;
  font-size: small;
  /* max-width: 200px; */
}
.client-select-box {
  min-height: 34px;
  font-size: small;
  /* max-width: 200px; */
}
.context-select-box {
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
/* .form-control {
  min-height: 38px;
} */
.label-name {
  font-size: 13px;
  font-weight: 500;
}
.add-button {
  color: #879096;
  cursor: pointer;
}
.task-add-body {
  max-height: 76vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: auto;
  overflow-x: hidden;
}
.inline-add-body {
  /* max-height: 70vh;
  overflow-y: scroll; */
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.adjustableWidth {
  min-width: 300px !important;
  /* max-width: 400px !important; */
}
.vertical-line {
  border-left: 1px solid #ced4da;
  min-height: max-content;
}
.toggle-content {
  width: fit-content;
}
.w-80 {
  width: 80%;
}

.myProject-addtask__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: var(--bg-primary);
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 11px;
}
.editor-style {
  min-height: 50px;
  max-height: 100px;
  /* line-height: 0.5; */
}
.editor-style-related {
  min-height: 50px;
  line-height: 1;
  max-height: 14vh;
}
.taskname-input {
  min-height: 40px !important;
  max-height: 70px !important;
  overflow-y: auto;
  font-size: 16px;
  padding-left: 5px;
  color: var(--main-text-color) !important;
  background: inherit;
}
.task-name-input {
  min-height: 33px !important;
  max-height: 70px !important;
  overflow-y: auto;
  height: 33px;
  font-size: 12px;
  min-width: 25vw;
  border-radius: 3px;
  border: 0.5px solid var(--second-text-color, #87909e);
}
.input-field {
  min-height: 25px !important;
  width: 115px !important;
  font-size: 12px;
}
.input-field-time {
  min-height: 25px !important;
  width: 85px !important;
  font-size: 12px;
}
/* single task css */
.task-single-card {
  border-radius: 4px;
  min-height: 75vh;
  margin: 23px;
  /* border-radius: 4px 0px 0px 4px; */
  background: var(--bg-primary);
}

.task-single-menu-item {
  /* color: #879096;
  font-size: 14px; */
  display: flex;
  min-width: 140px;
  color: var(--second-text-color, #87909e);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
.task-single-card-body {
  /* min-width: 50vw; */
  border: 1px solid var(--divider, #f1f1f1);
  border-radius: 4px 0px 4px 4px;
}
.task-single-menu-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-single-menu-value {
  /* color: #879096; */
  text-transform: capitalize;
  font-size: 14px;
  display: flex;
  color: var(--main-text-color, #2a2e34);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  align-items: center;
  gap: 3px;
}
.description {
  color: var(--second-text-color, #67677f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.task-menu {
  display: flex;
  gap: 15px;
  position: relative;
  margin: 0px 26px;
}
.task-menu-container {
  margin: 5px 27px;
}
.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-description-body {
  margin-top: 19px;
  padding: 20px;
  font-size: var(--regular-font, 13px);
  border-radius: 4px;
  border: 0.5px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
  transform: rotate(-0.001deg);
  flex-shrink: 0;
  max-height: 220px;
  overflow-y: scroll;
}
.task-single-menu-container {
  padding: 8px 12px;
}
.task-single-effort-time-box {
  background: #e7e7e7;
  padding: 2px 14px;
  border-radius: 3px;
}
.task-single-expected-time-box {
  background: #f5f5f5;
  padding: 2px 14px;
  border-radius: 3px;
}
.h-40px {
  height: 40px;
}

.action-button-start {
  display: flex;
  gap: 2px;
  background-color: var(--text-dark);
  border-radius: 3px;
  width: fit-content;
  border: 0px;
  padding: 3px 10px;
}
.action-button-title {
  color: var(--bg-primary);
  font-size: 13px;
}
.editable-field {
  /* width: 100%; */
  border: none;
  flex: 1;
  overflow-wrap: anywhere;
  text-wrap: balance;
  /* border-bottom: 1px solid #ccc; */
  /* padding: 5px; */
  /* font-size: 14px; */
  /* z-index: 99; */
}
.clr-grey {
  color: #879096;
}
.r-0 {
  right: 0px;
}

.editable-field span {
  display: inline-block;
  width: 100%;
  padding: 5px;
  cursor: pointer;
}
.time-input-container {
  top: 17px;
  position: relative;
}
.priority-box {
  display: inline-flex;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--border-color);
  height: 23px;
}
.priority-text {
  color: #1063ff;

  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  text-wrap: nowrap;
  font-weight: 500;
  line-height: normal;
}
.select-level-box {
  /* padding: 3px 5px;
  border-radius: 5px;
  font-size: 13px;
  border-color: #c8c8c8;
  border: none; */

  display: flex;
  /* width: 65px; */
  /* height: 23px; */
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
  font-size: 11px;
}
select option {
  /* Your styles here */
  display: flex;
  /* width: 65px; */
  /* height: 23px; */
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
  font-size: 11px;
  color: var(--text-color);
}

.subtask-container {
  margin-top: 19px;
  border-radius: 4px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
}

.subtask-list {
  /* max-height: 18vh; */
  overflow: auto;
  scrollbar-width: thin;
}
.close-box {
  box-shadow: 2px 5px 6px 0px var(--border-color);
  cursor: pointer;
  z-index: 8;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  width: 28px;
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}
.close-box :hover {
  background: var(--border-color);
  height: inherit;
  width: inherit;
  padding: 3px;
}

.close-box-container {
  position: relative;
}

.tags-container {
  background: #e7e7e7;
  padding: 1px 7px;
  border-radius: 3px;
}

/* filter css  */

.filter-container {
  width: 383px;
  height: fit-content;
  color: black;
  padding: 20px;
  font-size: 13px;
  border-radius: 8px;
  cursor: default;

  left: auto;
  min-width: 240px !important;
  max-width: 340px !important;
}

.filter-head {
  display: flex;
  justify-content: space-between;
  color: var(--text-dark);
}

.menu-item-clear {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-color) !important;
  cursor: pointer;
}

.filter-item-name {
  font-size: 10px;
  font-weight: 600;
  color: #656f7d;
  margin-bottom: 5px;
  margin-top: 0.8em;
}
.filter-body {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.filter-count {
  height: 14px;
  font-size: 10px;
  font-weight: 600;
  color: var(--bg-primary);
  width: 14px;
  position: absolute;
  border-radius: 100px;
  /* text-align: center; */
  background-color: #3454d1;
  left: 28px;
  bottom: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* sort css  */

.sort-item {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 20px;
  color: var(--icon-light);
}

.sort-item:hover {
  background: #f0f1f3;
  cursor: pointer;
}

.label-item {
  border-radius: 4px;
  width: fit-content;
  /* max-width: 100%; */
  padding: 0px 5px;
  color: rgba(135, 144, 150, 1);
  background: rgba(241, 242, 244, 1);
  margin: 4px 0;
  height: fit-content;
}
.labels-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  margin-top: 5px;
  /* grid-auto-flow: dense;
  grid-auto-columns: max-content; */
}

.priority-item {
  margin: 4px 0;
  padding: 3px 10px 4px 10px;
  border: 1px solid;
  width: fit-content;
  border-radius: 100px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
.related-task-container {
  justify-content: center;
  overflow-x: clip;
}
.related-task-wrapper {
  position: relative;
  height: 200px;
  overflow: overlay;
}
.task-single-related-task-add-container {
  position: sticky;
  bottom: 0px;
  background: var(--bg-primary);
  z-index: 100;
}
.task-inline-related-task-container {
  position: sticky;
  bottom: 0px;
  background: var(--bg-primary);
  z-index: 100;
}

.description-container-related {
  color: var(--second-text-color);

  padding: 20px 30px 15px 4px;
  border-bottom: 1px solid var(--border-color);
  font-size: 13px;
  font-weight: 400;
  max-height: 30vh;
  overflow: auto;
}
/* inline task add css */
.inline-task-name {
  /* border: 0px; */
  outline: #e5e5e5;
  width: 100%;
}
.inline-task-icon-container {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 33px;
  width: 170px;
  text-transform: capitalize;
}
.custom-icon {
  width: 33px;
  height: 33px;
}
.task-container {
  width: 33vw;
  min-height: 55vh;

  overflow-x: hidden;
  overflow-y: auto;
}
.template-container {
  --top-bar-height: 55px;
  border-left: 0.5px solid #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  max-height: calc(100vh - var(--top-bar-height));
  overflow: auto;
  min-width: 37vw;
  max-width: 37vw;
  padding: 12px 0px 0px 22px;
}

.inline-context-container {
  top: calc(100% + 5px);
  background: var(--bg-primary);
  position: absolute;
  z-index: 100;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.inline-curve-shadow {
  background: var(--bg-primary);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.inline-context-container-related-date {
  top: calc(100% + 17px);
  background: var(--bg-primary);
  position: absolute;
  z-index: 100;
  box-shadow: 0px 0px 5px 1px #d3d3d3;
  border-radius: 3px;
  right: 0;
}
.time-modal-context-container {
  /* top: calc(100% + 5px); */
  top: 25px;
  background: var(--bg-primary);
  position: absolute;
  z-index: 100;
  box-shadow: 0px 0px 2.5px 0.5px #d3d3d3;
  border-radius: 3px;
  /* left: 0; */
  width: 260px;
}
.start-time-popup {
  width: 235px !important;
}
.inline-description {
  right: 0;
  min-width: 17vw;
  max-width: 17vw;
}
.inline-related-description {
  right: 0;
  min-width: 17vw;
  max-width: 17vw;
}
.inline-user-select {
  right: 0;
  top: calc(100% + 0px);
  position: absolute;
  z-index: 99;
  border-radius: 3px;
  right: 0;
}

.custom-field {
  right: auto;
  width: auto;
}

.inline-time {
  right: 1px;
  min-width: 270px;
  padding: 10px 5px;
}

.inline-tag {
  left: 0;
}

.font-18 {
  font-size: 18px;
  font-weight: 600;
}
.inline-task-container {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-primary);
}

.inline-task-name-box {
  min-height: 23px !important;
  max-height: 70px !important;
  overflow-y: auto;
  height: 30px;
  min-width: 35vw;
  color: var(--second-text-color);
  font-weight: 500;
  border: none;
  display: flex;
  background-color: var(--bg-light);
}

inline-related-task-name-box:focus {
  outline: none;
  border: none;
}
.inline-related-task-name {
  /* width: 14vw; */
  font-size: 13px;
  font-weight: 500;
  margin-top: 4px;
}

.inline-related-task-name-box {
  min-height: 28px !important;
  max-height: 28px !important;
  overflow-y: auto;
  height: 23px;
  /* width: 240px !important; */
  color: var(--second-text-color);
  font-weight: 500;
  border: none;
  display: flex;
  padding: 7px 0px 0px 4px;
  background-color: var(--bg-primary);
}

.inline-task-item-container {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 30px;
  width: 32px;
  /* text-transform: capitalize; */

  cursor: pointer;
}
.inline-task-item-container.inline-submit {
  background-color: var(--accent-color);
  color: var(--icon-light);
}
.inline-task-item-container:hover {
  border: 1px solid var(--second-text-color);
}
.inline-related-task-item-container {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 23px;
  width: 23px;
  /* text-transform: capitalize; */
  cursor: pointer;
}
.inline-related-task-item-container:hover {
  border: 1px solid var(--second-text-color);
}
.inline-task-name-box:focus {
  outline: none;
  border: none;
}
.inline-related-task-name-box:focus {
  outline: none;
  border: none;
}

.inline-task-sub-head {
  font-weight: 600;
  color: var(--text-dark);
}

.inline-task-content {
  color: #172b4d;
  font-weight: 500;
}

.start-mode-text {
  color: #bc4841;
  padding: 0px 9px;
  font-size: 13;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 9px !important;
}
.timeline-text {
  color: var(--text-dark);
  font-size: 13px;
  font-weight: 500;
}
.inline-task-label-container {
  display: flex;
  flex-wrap: wrap;
}

.inline-assign-user-container {
  width: fit-content;
  min-width: 99px;
  max-width: 140px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);

  /* color: var(--second-text-color); */
  /* font-size: 13px; */
}
.inline-assign-user-container:hover {
  border: 1px solid var(--second-text-color);
}

.circular-clickable-btn {
  border: 0.5px solid rgba(135, 144, 150, 1);
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  gap: 5px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.search-list-container {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.custom-select-container {
  width: 181px;
  height: fit-content;
  color: black;
  padding: 20px;
  font-size: 13px;
  /* border-radius: 8px; */
  cursor: default;
  border-radius: 3px;
  right: 10px;
  padding: 10px 0px;
}
.search-icon-container {
  width: 38px;
  height: 32px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  background: var(--bg-primary);
  position: absolute;
  /* border-left: 0.5px solid #87909E
  ; */
  /* border: 1px solid; */
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}

.inline-task-search-input-container {
  position: relative;
  /* width: 300px; Adjust as needed */
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  /* padding: 8px 8px 8px 36px; */
  background-color: var(--bg-primary);
  display: flex;
  gap: 4px;
  align-items: center;
  padding-bottom: 5px;
  border-top: 1px solid var(--border-color);

  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.inline-task-search-input-container::before {
  /* content: "\1F50D"; Unicode for the search icon */
  position: absolute;
  left: 10px;
  /* font-size: 16px; */
  color: #ccc;
}

.inline-task-search-input {
  border: none;
  outline: none;
  width: 100%;
  /* font-size: 16px; */
}
.inline-task-search-input::placeholder {
  font-size: 12px;
  color: var(--second-text-color);
}
.inline-submit:hover {
  border: 1px solid var(--bg-primary);
}
.placeholderUser {
  margin-right: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
  display: inline-block;
}
.bold-border {
  border-width: 1px;
}

.circular-clickable-btn:hover {
  background-color: rgb(244, 244, 244);
}

.grey-label {
  color: rgba(135, 144, 150, 1);
}
.form-control :focus {
  box-shadow: 0 0 0 0.25rem rgb(168 225 251 / 25%);
}

.my-select {
  width: 300px;
}

.selected-values {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 10px;
}

.selected-value {
  margin-right: 10px;
  white-space: nowrap;
}

/*  single  page header start */
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  border-bottom: 1px solid var(--border-color);
}
.back-button-container {
  padding-right: 10px;
  margin-right: 15px;
  border-right: 1px solid var(--border-color);
  cursor: pointer;
}
/*  single  page header end */
/* single page task heading start */
.task-heading-container {
  display: flex;
  justify-content: space-between;
  min-height: 90px;
  align-items: center;
  padding: 0px 27px;
  gap: 2px;
  border-bottom: 1px solid var(--border-color);
}
/* single page task heading end */
/* updated container  task single view*/
.task-single-view-items-card {
  padding: 16px 27px;
  /* width: 50vw; */
  max-width: 100%;
}

.task-single-view-item-container {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 43px;
}

.task-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.inline-task-assign-user-field {
  border: 1px solid #ccc;
  padding: 1.5px 7px;
  border-radius: 2px;
}

.task-count-border-inactive,
.task-count-border-active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
}

.task-count-border-inactive {
  background: var(--bg-light-gray);
  color: var(--second-text-color);
  padding: 2px 3px;
}

.task-count-border-active {
  color: #3454d1;
  background: var(--bg-primary);
  padding: 2px 3px;

  /* Add your specific styles for active state here */
}

.task_tab_active {
  padding: 4px 6px;
  background: var(--bg-light-gray);
  border-radius: 8px;
  /* border-bottom: 2px solid var(--Base-color); */
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  box-shadow: var(--input-border-shadow);
  /* z-index: 2; */
  min-width: fit-content;
}

.task_tab_inactive {
  padding: 4px 6px;
  gap: 5px;
  /* border-bottom: 1.6px solid red; */
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: fit-content;
}

.task-list-container {
  border-radius: 4px;
  padding: var(--top-task-tab-padding);
  width: 100%;

  margin-bottom: 5rem;
}

.cursor-default {
  cursor: default;
}

.menu-item-outline {
  height: 34px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}
.disabled-menu-item-outline {
  height: 34px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: not-allowed;
  border-radius: 4px;
}
.menu-item-outline:hover {
  background: #f3f5f7;
}
/* .active {
  background: #e9f2ff;
  color: #3454d1;
} */

.table-head {
  position: sticky;
  top: 0;
  /* background-color: var(--bg-primary); or your desired background color */
  z-index: 1;
  background-color: var(--bg-light-gray);
  white-space: nowrap;
}

.overflow-multiline {
  display: -webkit-box;
  line-break: auto;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22vw;
  /* text-transform: capitalize; */
  /* max-width: 300px; Adjust this to your desired width */
}

.action-button-outline {
  width: fit-content;
  border: 1px solid var(--border-color);
  padding: 0px 12px 0px 12px;
  height: 32px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
}
.todo-action-button-outline {
  display: flex;
  width: 65px;
  height: 23px;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
}
.todo-text {
  color: var(--main-text-color, #2a2e34);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.completed-text {
  color: var(--main-text-color, #2a2e34);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.cursor-no-drop {
  cursor: no-drop;
}

.task-table thead tr th {
  font-size: 13px;
  font-weight: 500;
  color: var(--second-text-color);
}

.label-item-task-list {
  border-radius: 4px;
  width: fit-content;
  padding: 2px 9px;
  height: fit-content;
  font-size: 11px;
  /* cursor: pointer; */
}
.task-title {
  width: 22vw;
}

.task-list-description {
  color: var(--common-description-text, #87909e);
  font-size: 13px;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
  display: -webkit-box;
  line-break: auto;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22vw;
  margin: 5px 0px;
}

.label-item-filter {
  border-radius: 4px;
  width: fit-content;
  padding: 2px 9px;
  height: fit-content;
  font-size: 11px;
  cursor: pointer;
  margin: 4px 0;
}

.message-count {
  color: #dd476a;
  background-color: #d6aeb6;
  width: fit-content;
  border-radius: 100px;
  padding: 0px 6px;
  font-size: 12px;
  font-weight: 600;
}

.task-bg-grey {
  /* width: 97% !important; */
  background: var(--bg-light-gray);
  color: var(--icon-light);
}
.task-list-chat {
  align-items: flex-start;
  color: #656f7d;
  display: flex;
  font-size: 13px;
  gap: 6px;
  margin: 5px 0;
}

.task_tab_contain {
  display: flex;
  gap: 18px;
  max-width: 97%;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding: var(--top-task-tab-padding);
}
.task_tab_contain > div:hover {
  cursor: pointer;
  /* background-color: var(--border-color); */
  /* border-radius: 8px; */
}
.task_tab_contain::-webkit-scrollbar {
  display: none; /* WebKit browsers */
}
.task_line {
  height: 0.5px;
  width: 97%;
  background: var(--bg-light-gray);
  position: absolute;
  top: 32px;
  z-index: 0;
}
.border-none {
  border: none;
  resize: none;
}
.border-none:focus {
  border: none;
  outline: none; /* Optional: removes the default outline */
  box-shadow: none;
}

.task-icon-bg {
  padding: 2px;
  border: 0.5px solid var(--bg-light-gray) !important;
  border-radius: 8px;
  min-width: 81px;
  height: 28px;
  display: flex;
  justify-content: center;
}

.todo-icon-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid grey;
  padding: 5px;
  font-size: 12px;
  border-radius: 16px;
  width: 65px;
  height: 23px;
  color: var(--text-dark);
  font-weight: 500;
}

.dot-icon {
  width: 6px;
  height: 6px;
  background-color: #3454d1;
  border-radius: 3px;
}
.subtask-icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-primary);
  border-radius: 6px;
  height: 23px;
  width: 23px;
  border: 1px solid #0c0b0b14;
  color: grey;
  cursor: pointer;
}
.subtask-view {
  padding: 8px 15px;
}
.subtask-add-box-border {
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.delay-overflow-visible {
  overflow: hidden;
  animation: overflowVisible 0ms ease-in-out 450ms forwards;
  padding: 0px 20px;
}
@keyframes overflowVisible {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

.label-container-related {
  /* background-color: #fdecf5;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  color: #ad3977; */
  /* background-color: #fdecf5;  */
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  /* height: 22px; */
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  color: #9a9a9a;
}

.min-width-95px {
  min-width: 95px;
}
.label-count-container {
  background-color: #f3f5f7;
  height: 22px;
  width: 22px;
  padding: 1px;
  border-radius: 10px;
  font-size: 10px;
  color: var(--second-text-color);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.rotate-down-related {
  transform: rotate(90deg);
  transition: transform 300ms ease-in-out;
}
.rotate-up-related {
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;
}

.anim-hide {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 300ms ease-in-out, margin 300ms;
}
.template-rows-0fr {
  grid-template-rows: 0fr;
}
.subtask-input-box {
  width: 675.613px;
  height: 40px;
  transform: rotate(-0.001deg);
  flex-shrink: 0;
}
.border-bottom {
  border-bottom: 1px solid var(--border-color) !important;
}
.subtask-view:hover .subtask-view-actions {
  visibility: visible !important;
}

.font-16 {
  font-size: 16px;
  font-weight: 600;
}

.font-12 {
  font-size: 12px;
  font-weight: 500;
}

.no-wrap {
  white-space: nowrap;
}
.clear-button-list {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 20px;
  border-top: 1px solid var(--border-color);
  margin-top: 10px;
}

.clear-button-list:hover {
  cursor: pointer;
}

.inline-priority-list {
  width: 383px;
  height: fit-content;
  color: black;
  padding: 20px;
  font-size: 13px;
  border-radius: 8px;
  cursor: default;
  width: 160px;
  border-radius: 3px;
  right: 0;
  padding: 10px 0px;
}
.inline-related-priority-list {
  width: 383px;
  height: fit-content;
  color: black;
  padding: 20px;
  font-size: 13px;
  border-radius: 8px;
  cursor: default;
  width: 160px;
  border-radius: 3px;
  left: 0;
  padding: 10px 0px;
}

.inline-container {
  padding-left: 2vw;
}

.tab-click-pop-up {
  display: none;
}

/* .tab-click:focus .tab-click-pop-up, */
.tab-click:focus-within .tab-click-pop-up {
  display: block !important; /* Show popup when parent div is focused */
}

.tab-click:focus-visible {
  outline: 1px solid var(--second-text-color); /* Add focus outline when parent div is focused */
}

/* border: 1px solid var(--second-text-color); */

.position-right {
  right: 0;
}

/* HorizontalScrollMenu.css */

.inline-task-description {
  border: none;
}

.inline-task-description {
  padding: 5px;
  border-radius: 4px;
  font-size: 13px;
  padding-top: 0px;
  max-height: 14vh;
}

.custom-editor .rdw-editor-toolbar .rdw-option-wrapper img {
  /* font-size: 12px; */
  width: 10px;
  height: 10px;
}

.horizontal-scroll-menu {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  /* Add other styling properties as needed */
  width: 170px;
  scrollbar-width: none;
}

.extra-values {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background: var(--bg-light-gray);
  border-radius: 20px;
  color: var(--second-text-color, #87909e);
  font-family: 'Plus Jakarta Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.menu-item {
  white-space: nowrap;
  padding: 2px 2px;
  margin-right: 10px;
  font-size: 14px;
}
/* reminder css start */
.reminder-container {
  border-radius: 4px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
  padding: 6px;
}

.reminder-empty-container-text {
  color: var(--main-text-color, #2a2e34);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.reminder-input-box {
  height: 30px;
  /* min-width: 27vw; */
  color: var(--second-text-color);
  font-weight: 500;
  border: none;
  display: flex;
  font-size: 13px;
  border-radius: 3px;
}

.reminder-input-box:focus {
  outline: none;
  border: none;
}

.inline-add-button {
  border: none;
  gap: 1px;
  padding: 4px;
  border-radius: 4px;
  background: var(--Base-Color, #3454d1);
  display: flex;
  align-items: center;
  color: var(--bg-primary);
  width: 85px;
  justify-content: center;
}
.error-border {
  border: 1px solid rgb(228, 1, 1) !important;
}
.error-border-bottom {
  border-bottom: 1px solid rgb(228, 1, 1) !important;
}
/* action component */

.button-container {
  border: 1px solid var(--border-color, rgba(135, 144, 158, 1));
  border-radius: 4px;
  display: flex;
  align-items: 'center';
  padding: 4px 8px;
  color: var(--second-text-color, #87909e) !important;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.action-list-button {
  display: flex;
  width: max-content;
  height: 32px;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-dot {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--Base-Color, #3454d1);
}
.action-button {
  display: flex;
  height: 30px;
  padding: 0px 5px;
  width: 114px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 8px;
  font-size: 12px;
  border: none;
  text-wrap: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  box-shadow: var(--button-shadow);
  background: var(--bg-primary);
}

.action-button-complete-tick {
  display: flex;
  height: 30px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-primary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  /* color: var(--hover-verify, #eaebef); */
  color: #17c666;
}

.action-button-complete-tick:hover {
  border: 1px solid #17c666;
  color: #17c666;
}
.action-button-repeat-tick {
  display: flex;
  height: 30px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-primary);
  border-radius: 4px;
  border: 1px solid var(--hover, #eaebef);
  color: var(--hover, #eaebef);
  /* color: #ff6347; */
}
.action-button-repeat-tick:hover {
  border: 1px solid #ff6347;
  color: #ff6347;
}

.action-button:hover {
  border: 1px solid var(--second-text-color, #87909e);
}

.action-button-stop {
  display: flex;
  font-size: 12px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-primary);
  border-radius: 8px;
}

.action-button-text {
  font-size: 13px;
  color: var(--main-text-color, #2a2e34);
  white-space: nowrap;
}
.action-button-start-text {
  color: #546272;
  font-weight: 600;
}
.action-button-stop-text {
  font-size: 13px;
  color: var(--Base-Color, #546272);
}

/* task single view texts */
.task-single-view-name {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.border-active {
  border: 1px solid var(--border-color);
  padding: 0px;
  border-radius: 3px;
}

.border-active:target {
  border: 1px solid var(--second-text-color);
  /* outline: 0px; */
}

.border-active:focus-visible {
  border: 1px solid #87909e !important;
  outline: 0px;
}

.date-input-container {
  background: #f2f2f2;
  height: 30px;
  width: fit-content;
  border: none;
  padding: 2px 5px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  color: gray;
  display: flex;
  align-items: center;
}

.editable-time-line {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--main-text-color, #2a2e34);
  white-space: nowrap;
}

/* Task WYSIWYG EDITOR */

.task-add-description-container .editor-style {
  padding: 0px;
  /* cursor: text; */
  padding: 5px 13px 13px 13px;
  /* line-height: 0.5; */
}

/* .task-add-description-container .editor-style div[contenteditable='true']:focus {
} */
.task-add-description-container .wrapper-style {
  padding: 0 !important;
  border: 1px solid var(--border-color);
}
.task-add-description-container .editor-toolbar {
  padding: 5px 13px;
  border-radius: 4px;
  background: var(--bg-light-gray);
  margin: 0;
  border: none;
  border-bottom: 1px solid var(--border-color);
}
.task-add-description-container .editor-toolbar .EditorIconOption {
  border: none;
}
.task-add-description-container .editor-toolbar .EditorIconOption:hover {
  border: none;
  box-shadow: none;
  background-color: var(--border-color);
}
/* wrapperClassName='wrapper-style wrapper-width'
editorClassName='editor-style'
toolbarClassName='editor-toolbar' */

.task-description-dummy:hover {
  background-color: var(--border-color);
}

/* .wrapper-style:focus-within {
  border: 1px solid blue;
} */

.time-edit-container {
  position: absolute;
  background: var(--bg-primary);
  top: 40px;
  border: 1px solid var(--divider, #f1f1f1);
  border-radius: 3px;
  width: 215px;
  left: 143px;
}

/* date compoenent  */
.date-range-container {
  display: flex;
  flex-direction: column;
}

.date-range-label {
  margin-bottom: 2px;
}
.date-range-picker {
  transform: scale(1); /* Adjust the scale as needed */
}

/* time context view */
.time-context-view {
  position: absolute;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  background: var(--bg-primary);
  width: 245px;
  max-width: 245px;
  z-index: 100;
  top: 35px;
  left: 140px;
}

.related-assignee-container {
  position: absolute;
  z-index: 10;
  right: 0;
}

/* task  file start upload */
.attachment-heading {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
}
.attachment-upload-container {
  /* width: 675.613px; */
  height: 38.996px;
  transform: rotate(-0.001deg);
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--bg-primary);
  display: flex;
  justify-content: center;
}
.attachment-container {
  border-top: 1px solid var(--divider) !important;
  margin: 2rem 0rem;
  padding: 1rem 0rem;
}
.file-container {
  width: 30%;
  height: 178.173px;
  transform: rotate(-0.001deg);
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--divider, #f1f1f1);
}
.file-view {
  width: 100%;
  height: 75%;
  border-radius: 3px 3px 0px 0px;
  border: 1px solid var(--divider, #f1f1f1);
  background: var(--divider, #f1f1f1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-text-container {
  padding: 2px 2px;
}
.file-text {
  color: var(--main-text-color, #2a2e34);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.file-size {
  color: var(--second-text-color, #87909e);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 175% */
}
.doc-item {
  display: flex;
  border-radius: 35px;
  align-items: center;
  -webkit-backdrop-filter: unset;
  backdrop-filter: unset;
  width: 55px;
  height: 55px;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.upload-files-container {
  display: flex;
  /* justify-content: space-between; */
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
  gap: 2rem;
  min-height: 180px;
}

.file-container:hover > .file-option-delete {
  position: absolute;
  top: 4px;
  right: 2px;
  background: var(--bg-primary);
  padding: 5px 6px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
}
.file-container:hover > .file-option-download {
  position: absolute;
  top: 4px;
  right: 30px;
  background: var(--bg-primary);
  padding: 5px 6px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
}
.file-option-delete {
  display: none;
}
.file-option-download {
  display: none;
}

.pin-container {
  background: var(--btn-bg-light, #f3f5f7);
}

.inline-task-container:hover .icon-div {
  visibility: visible !important;
}
/* .file-option-delete {
  position: absolute;
  top: 6px;
  right: 2px;
  background: var(--bg-primary);
  padding: 5px 6px;
  border-radius: 4px;
  display: flex;
}
.file-option-download {
  position: absolute;
  top: 6px;
  right: 30px;
  background: var(--bg-primary);
  padding: 5px 6px;
  border-radius: 4px;
  display: flex;
} */
/* task  file end upload */

/* ------------------------- */

/* ------------------------- */

.task-running {
  width: 90px;
  background: var(--icon-light);
  /* background: rgb(37, 37, 37); */
  color: var(--bg-primary);
  border: 1px solid var(--border-color);
  padding: 4px 10px;
  /* height: 37px; */
  border-radius: 5px;

  transition: width 0.3s ease, hight 0.2s ease; /* Add transition for smooth animation */
}
.task_time {
  font-size: 12px;
  font-weight: 400;
  display: none;
}
.double-tab {
  font-size: 9px;
  display: none;
}

.task-running:hover {
  width: 230px; /* Increase the width on hover */
  height: 65px;
}
.task-running:hover .double-tab {
  display: block;
}
.task-running:hover .task_time {
  display: block;
}

.task-running-name {
  white-space: nowrap;
  overflow: hidden;
}

.task-running:hover .task-running-name {
  text-overflow: ellipsis;
}
.user-filter-clear {
  margin: auto;
  padding: 5px 9px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}
.user-filter-clear:hover {
  background-color: var(--border-color);
}
.task-single-tabs {
  display: flex;
  gap: 18px;
  overflow-x: scroll;
  scrollbar-width: none;
  /* border-bottom: 1px solid #dee2e6; */
}
.task-single-tabs .task-single-tab {
  white-space: nowrap;
}

.task-time-container {
  flex-wrap: wrap;
}
.related-line-items {
  min-height: 30px;
}
.task-breadcrumbs {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.action-button-container {
  display: flex;
  gap: 0.25rem;
  position: relative;
}

.task-label-modal {
  width: 25vw;
  min-height: 20vh;
  margin-top: 10%;
  border-radius: 3px;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-menu-item {
  height: 30px;
  width: 30px;
}

.task-label-modal .cancel-btn {
  border: 1px solid var(--border-color);
  width: 90px;
  padding: 4px 21px;
}

.add-task-related-task-heading {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  position: sticky;
  top: -14px;
  background: var(--bg-primary);
  z-index: 9;
}

.slogan-header {
  font-weight: 700;
  font-size: 15px;
  white-space: nowrap;
}

.is-exceeded {
  background: #fcedf0;
  border-radius: 3px;
}

.related-task-body {
  overflow: scroll;
  scrollbar-width: none;
}

.task_tab_menu_line {
  height: 1px;
  background-color: #d2d2d2;
  position: relative;
  top: -11px;
  /* z-index: -1; */
  /* width: 100%; */
  margin: 0px 26px;
}

.sub-section {
  overflow: auto;
  scrollbar-width: none;
}

.sub-task-name {
  /* white-space: nowrap; */
  min-width: 130px;
}
.subtask-date-content {
  width: 120px;
  font-size: 12px;
  line-height: 1;
  height: 30px;
  padding: 0px 8px;
}

.subtask-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  justify-content: flex-end;
}

.subtask-time-content {
  width: 90px;
  font-size: 12px;
  line-height: 1;
  height: 30px;
  padding: 0px 8px;
}
.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 8px;
  width: max-content;
  height: 28px;
  font-size: 12px;
}

.date-input,
.time-input {
  border: none !important;
  outline: none;
  padding: 5px;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  width: 85px;
  height: 30px !important;
  justify-content: center;
  align-items: center;
}

.time-input::-webkit-calendar-picker-indicator {
  display: none; /* Hides the default time picker icon in Chrome, Safari, Edge */
}

.time-input::-webkit-inner-spin-button {
  display: none; /* Hides the inner spin button in Chrome, Safari, Edge */
}

.time-input::-moz-calendar-picker-indicator {
  display: none; /* Hides the default time picker icon in Firefox */
}

.okr-add-button {
  display: flex;
  color: var(--second-text-color);
  align-items: center;
  justify-content: center;
}
.okr-add-button:hover {
  color: var(--main-text-color);
}

.notebook-pen-container {
  cursor: pointer;
  display: flex;

  padding: 3px;
}

.notebook-pen-container:hover {
  border-radius: 50%;
  background-color: #d2d2d2;
}

.inline-task {
  background: var(--bg-primary);
  border-radius: 4px;
  position: sticky;
  margin-top: -2px;
  bottom: 0px;
  background: var(--bg-primary);
  /* box-shadow: 0px 0px 8px 0px #e0e0e0; */
  border: 1px solid var(--border-color);
}

span.single-okr-strip {
  background: var(--btn-bg);
  color: var(--text-color);
  border: 1px solid var(--btn-border);
  padding: 0 10px;
  border-radius: 4px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.react-datepicker-popper {
  z-index: 100;
}

.parent-task-name {
  padding: 2px 9px;

  background-color: #00900017;
  box-shadow: rgb(0 182 25 / 6%) 0px 0px 0px 1px inset;

  border-radius: 8px;
  width: fit-content;
  font-size: 12px;

  padding-top: 0;
  /* no warp online elipse */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.tr-inline-task-add:hover {
  background-color: transparent !important;
}

.start-mode-item {
  display: flex;
  padding-top: 7px;
}
.related-task-content {
  display: flex;
  align-items: center;
  gap: 2px;
}
.related-task-item-head {
  width: 125px;
}
.related-item-container {
  display: flex;
  gap: 1.25rem;
  height: 35px;
}
.related-item-task-text {
  font-size: 13px;
  font-weight: 500;
  color: var(--second-text-color);
}

@media screen and (max-width: 768px) {
  .task-add-container {
    margin-top: 0px;
    width: 95%;
  }
  .task-single-card {
    margin: 60px 10px 10px 10px;
    height: 100vh;
    overflow: auto;
    /* padding-bottom: 160px; */
  }
  .task-title {
    width: 100%;
  }
  .subtask-add-button {
    position: relative !important;
  }
  .subtask-content {
    flex-direction: column;
  }
  .timeline-text .date-range-calendar {
    right: 0px !important;
    left: -110px !important;
  }
  .sub-section {
    padding-bottom: 30px;
  }
  .table-head {
    position: relative;
  }
  .overflow-multiline {
    max-width: 75vw;
  }
  .task-breadcrumbs {
    display: none;
  }
  .table-container .responsiveTable tbody tr {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    margin-bottom: 10px;
    page-break-after: avoid;
    break-after: avoid;
    padding: 0.25em;
    background: var(--bg-primary);
  }

  .table-container .responsiveTable tbody td {
    border-bottom: none;
  }
  .responsiveTable td .tdBefore svg {
    width: 18px;
    height: 15px;
    color: #ababab;
  }
  .table-container {
    border: none !important;
    max-height: initial !important;
    margin-top: 210px;
    padding: 0px 17px;
  }
  .task-list-container .table-container {
    /* margin-top: 220px; */
    top: 20px;
    position: relative;
    height: 100vh;
    /* padding-bottom: 285px; */
  }

  .table-container .task-list-name {
    color: #4d94ff;
  }
  .ask-single-card .chat-list-content {
    position: relative !important;
  }
  .tr-inline-task-add {
    display: none !important;
  }
  .task_tab_contain {
    padding: 0px;
  }
  .task-list-container {
    /* padding: var(--top-breadcrumb-padding-responsive); */
    padding: 0px;
  }
  .filter-container {
    width: 70vw;
    position: relative;
    right: 200px;
  }

  .task-single-menu-value .filter-container {
    left: -76px;
    z-index: 10;
  }
  .custom-field {
    width: 75vw;
    height: 56vh;
  }
  .rdrMonth {
    width: 70vw !important;
  }
  .file-container {
    width: 100%;
  }
  .inline-related-description {
    left: 0;
    min-width: 65vw;
    max-width: 17vw;
  }
  .inline-calendar {
    left: 28px;
    top: 14px;
  }
  .related-assignee-container {
    right: -80px;
  }
  .task-single-view-items-card {
    display: contents;
  }
  .task-menu {
    margin: 8px 15px;
  }
  .task-menu-container {
    margin: 8px 15px;
  }
  .time-context-view {
    left: 54px;
    box-shadow: 0px 0px 1px 0px;
  }
  .reminder-input-box {
    min-width: 27vw;
  }

  .task-menu-container .work-activity {
    margin-top: -16px;
  }

  /*task ---- tabs*/
  .task-list .task_tabs {
    position: absolute !important;
    z-index: 8 !important;
    width: 100%;
    top: 96px;
    background: var(--bg-primary);
    padding: 8px 17px;
  }

  /*  */
  .task-list-container .tab_contain {
    top: 128px;
    gap: 4px;
  }
  .task-list-container .tab_contain.no_task {
    top: 87px;
    position: absolute;
  }
  .task-list .time-box-filter {
    margin-top: 10px;
  }
  .action-button-container {
    padding-top: 5px;
  }
  .task-label-modal {
    width: 76vw;
  }
  .task_add_form_content {
    flex-direction: column;
  }
  .delay-overflow-visible {
    overflow: hidden !important;
  }
  .related-item-container {
    display: contents;
  }
  .related-task-content {
    padding: 5px 0px;
    height: 30px;
  }
  .start-mode-text {
    padding-left: 0px !important;
  }
  .time-modal-context-container {
    left: 0px;
  }
}

.task-table tr {
  vertical-align: middle;
}
