.config_layout {
  font-family: Arial, sans-serif;
  max-width: 523px;
  border-radius: 8px;
  transition: all 0.3s;
  padding: 0px 5px;
}
.config_heading_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 0px;
  gap: 0.75rem;
}
.headers_container {
  border: 1px solid var(--border-color);
  background: var(--bg-primary);
  width: 523px;
  flex-shrink: 0;
  margin: 10px 0px;
}
.headers_heading_container {
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--border-color);
  background: #f3f5f7;
}
.headers_input_container {
  border: none;
  height: 30px;
  outline: none;
  width: 13rem;
}
.headers_table {
  border: 1px solid var(--border-color);
}

.body_text_container {
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary);
  width: 100%;
  height: 203px;
  margin-top: 10px;
  outline: none;
}
.icon_container {
  width: 29px;
  height: 29px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary);
}

.config_child_tab {
  display: inline-flex;
  padding-bottom: 5px;
  align-items: center;
  color: var(--second-text-color, #67677f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.child_tab_active {
  border-bottom: 2px solid #3454d1;
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.label-text {
  color: var(--second-text-color);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 18.6px */
}

.border-f0 {
  border: 1px solid var(--border-color);
}
