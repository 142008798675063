.log-out-modal-container {
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  background-color: var(--bg-primary);
  padding: 15px;
  width: 220px;
  height: 170px;
  z-index: 99999;
  border: 2px solid #0c0b0b14;
}

.user-name {
  font-size: 16px;
  font-weight: 500;
}

.logout-text {
  font-size: 15px;
  font-weight: 500;
}

.details-heading {
  font-size: 12px;
  color: #879096;
  font-weight: 400;
}
.details-value {
  font-size: 14px;
  color: #172b4d;
  font-weight: 500;
  height: 10px;
}
.bb-solid {
  border-bottom: 1px solid #e5e9ef;
}

.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}

.image-preview-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.263);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.image-preview-card {
  max-width: 500px;
  min-width: 100px;
  background-color: var(--bg-primary);
}
.image-preview-button-div {
  display: flex;
  gap: 10px;
}

.image-preview-button {
  width: 50%;
}

.profile-nav-contain {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid var(--border-color);
  padding-top: 20px;
}
