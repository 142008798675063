.notification-modal-container {
  position: absolute;
  right: 0;
  top: calc(100% + 19px);
  background-color: var(--bg-primary);
  padding: 10px;
  width: 421px;
  max-height: 503px;
  z-index: 99999;
  border: 1px solid #0c0b0b14;
  border-radius: 4px;
  background: var(--bg-primary);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
}

.notification_heading {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.notification_menu_active {
  font-weight: 500;
  font-size: 13px;
  color: black !important;
  text-decoration: none;
  /* background: red; */
  border-bottom: 1.8px solid black;
  padding: 0px 2px 2px 2px;
}
.notification_menu_inactive {
  font-weight: 400;
  font-size: 13px;
  color: black;
  text-decoration: none;
  /* background: red; */
  padding: 0px 2px 2px 2px;
  opacity: 0.5;
}

.update_container {
  width: 100%;
  max-height: 135px;
  background-color: var(--bg-primary);
  padding: 8px;
  font-weight: 400;
  font-size: 13px;
}
.chat_condainer {
  width: 100%;
  max-height: 85px;
  background-color: var(--bg-primary);
  padding: 12px;
  font-weight: 400;
  font-size: 13px;
}

.notification_icon_bg {
  width: 40px;
  height: 40px;
  background-color: #4786ff;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
}

.notification_head {
  font-size: 14px;
  font-weight: 500;
}
.notification_msg {
  font-size: 13px;
  color: #879096;
}
.update_head {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
.task_head {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
.task_body {
  color: var(--3rd-text-color, #656f7d);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 5px;
  align-items: center;
}
.update_body {
  color: var(--3rd-text-color, #656f7d);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 5px;
  align-items: center;
}
.update_date_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notification_msg {
  font-size: 13px;
  color: #879096;
}
.notification_date {
  color: var(--second-text-color, #87909e);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.update_notification_modal_container {
  max-height: 340px;
  overflow: auto;
  margin-top: 18px;
}

.view_all {
  color: var(--3rd-text-color, #656f7d);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.notification_sidebar {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  row-gap: 5px;
  width: 110px;
}

.notification-count {
  color: #dd476a;
  width: 22px;
  background-color: #fcedf0;
  width: -moz-fit-content;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
}

.notification_tabs {
  /* margin: 15px 0px 15px 0px; */
  position: relative;
  width: 100%;
}
.notification_line {
  height: 0.5px;
  width: 100%;
  background: var(--bg-light-gray);
  position: absolute;
  top: 32px;
  z-index: -1;
}

.notification_tab_contain {
  display: flex;
  gap: 18px;
  /* padding-left: 15px; */
  padding: 10px 24px 0px 24px;
  background: var(--bg-primary);
}
.notification_tab_active {
  padding: 1px 1px 9px 1px;
  border-bottom: 2px solid #3454d1;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
}
.notification_tab_inactive {
  padding: 1px 1px 9px 1px;
  gap: 5px;
  /* border-bottom: 1.6px solid red; */
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notification-count-border-inactive,
.notification-count-border-active {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 22px;
  height: 22px; */
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  padding: 0px 5px;
}

.notification-count-border-inactive {
  background: #f3f5f7;
  color: var(--second-text-color);
}

.notification-count-border-active {
  color: #3454d1;
  background: #e9f2ff;
  /* Add your specific styles for active state here */
}

.full-view-container {
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: var(--bg-primary);
}

.notification-icon-empty-container {
  height: 40px;
  width: 40px;
  border-radius: 25px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-mt {
  margin-top: 4px;
}

.un-read-text {
  color: var(--3rd-text-color, #656f7d);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 3px;
}
