/* DateTimePicker.css */
.datepicker {
  display: inline-flex;
  position: relative;
  overflow: clip;
}

.datepicker:focus-within {
  /* outline: black auto 2px; */
}

.datepicker > input[type='datetime-local'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.datepicker > input[type='datetime-local']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: -150%;
  left: -150%;
  width: 300%;
  height: 300%;
  cursor: pointer;
}

.date_txt {
  font-size: 11px;
  border: 1px solid #d2d2d2;
  padding: 2px 8px 2px 8px;
  border-radius: 50px;
}

.sub-task-date {
  /* border: 1px solid #7e7e7e96; */
  font-size: 11px;
  padding: 4px 9px;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: 500;
}

.date-field-date-picker {
  width: 130px;
}

.date-range-calendar {
  position: absolute;
  z-index: 1000;
  background: var(--bg-primary);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .activity-reports-filter-container .date-range-calendar {
    right: 0px !important;
  }
}
