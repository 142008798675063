.template-add-container {
  /* .If temp is modal, use this height */
  --modal-height: calc(100vh - 96px - 68px);
  /* If temp is page, use this height */
  --page-height: calc(100vh - var(--top-bar-height) - 68px - 69px);

  --top-bar-height: 55px;
  /* padding-top: 20px; */
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  /* Temp */
  /* 86 is the title height; adding 10 it for better cleaner look, which gives 96px */
  /* 68px is the button section */
  max-height: var(--page-height);
  height: var(--page-height);
  overflow: auto;
}

.z-index-highest {
  z-index: 999999;
}

.template-container-horizontal-padding {
  padding-inline: 24px;
}
.template-container-vertical-padding {
  padding-top: 19px;
  padding-bottom: 19px;
}
.template-container-bottom-padding {
  padding-bottom: 19px;
}
.template-container-horizontal-margin {
  margin-inline: 19px;
}
.template-container-vertical-margin {
  margin-top: 19px;
  margin-bottom: 19px;
}

.template-add-container .mt-30px {
  margin-top: 30px;
}
.template-add-container .my-30px {
  margin-top: 30px;
  margin-bottom: 30px;
}
.template-add-container .mt-20px {
  margin-top: 20px;
}
.template-add-container .mt-8px {
  margin-top: 8px;
}
.template-add-container .mt-13px {
  margin-top: 13px;
}
.template-add-container .my-20px {
  margin-top: 20px;
  margin-bottom: 20px;
}

.template-add-container .mt-5px {
  margin-top: 5px;
}

.template-add-container .mt-10px {
  margin-top: 10px;
}

.template-add-container .pt-10px {
  padding-top: 10px;
}

.template-add-container .pt-20px {
  padding-top: 20px;
}

.template-add-container .title,
.template-modal-form .title,
.template-root .title {
  font-size: 16px;
  font-weight: 600;
}

.template-add-container .input-label {
  font-size: 13px;
  font-weight: 500;
}

.template-add-container .template-create-section {
  /* padding-inline: 30px; */
  border-right: 1px solid var(--border-color);
}

.template-add-container .px-30px,
.template-modal-root-container .px-30px,
.template-root .px-30px {
  padding-inline: 30px;
}

.template-add-container .template-preview-section {
  /* background-color: bisque; */
  /* padding-inline: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.template-add-container .half {
  width: 50%;
  flex: 1;
  min-width: 400px;
}

.template-add-container label {
  user-select: none;
  font-size: 14px;
  font-weight: 400;
}

.template-add-container input[type='radio'] {
  width: 13px;
  height: 13px;
  accent-color: var(--accent-color);
}
.template-add-container .sub-title {
  font-size: 15px;
  font-weight: 500;
}

.template-add-container .status-group-prev-container,
.template-add-container .grey-rounded-container {
  font-size: 12px;
  font-weight: 400;
  /* background-color: #f5f5f5; */
  border: 0.5px solid var(--border-color);
  padding: 15px 14px;
  border-radius: 3px;
}
.template-add-container .status-prev-item {
  /* background-color: #eaeaea; */
  border: 0.5px solid var(--second-text-color, #87909e);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  border-radius: 100px;
  gap: 5px;
}

.template-add-container .status-dot {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  color: black;
}
.template-add-container .sub-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}

.template-add-container .fw-500 {
  font-weight: 500;
}
.template-add-container .fw-600 {
  font-weight: 600;
}

.template-add-container .fs-13px {
  font-size: 13px;
}

.template-add-container .grey-text {
  color: #879096;
}

.template-add-container .title-small {
  font-size: 13px;
  font-weight: 500;
}

.template-add-container .submit-btn {
  user-select: none;
}

.template-add-container .expected-time {
  height: 32px;
}

.template-add-container .grey-txt {
  color: #879096;
}

.template-add-container .reset {
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}

.template-add-container input[type='checkbox'] {
  accent-color: var(--accent-color);
}

.template-add-container input[type='time'] {
  border: 0.5px solid #879096;
  border-radius: 3px;
  height: 38px;
  padding: 12px;
}

.template-add-container .btn-container {
  position: sticky;
  bottom: 0px;
  padding: 20px 30px;
  /* border-top: 1px solid var(--border-color); */
  z-index: 999;
  background-color: var(--bg-primary);
}

.template-add-container .top-light-shadow,
.template-modal-root-container .top-light-shadow,
.template-root .top-light-shadow {
  --shadow: #091e4229;
  box-shadow: 0 -6px 5px -5px var(--shadow, #091e4229);
}

.template-add-container .px4px-py3px {
  padding: 3px 4px;
}

.template-add-container .btn-wrapper,
.template-list-table .btn-wrapper {
  --wrapper-left: -5px;
  color: black;
  user-select: none;
  position: relative;
  cursor: pointer;
}

.template-add-container .btn-wrapper:hover::before,
.template-list-table .btn-wrapper:hover::before {
  content: '';
  /* background-color: #f3f3f3; */
  cursor: pointer;
  position: absolute;
  height: 100%;
  inset: 0;
  left: var(--wrapper-left);
  border-radius: 2px;
  z-index: -1;
}

.template-list-table .fs-14px {
  font-size: 14px;
}

.template-add-container .anim-hide {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 300ms ease-in-out, margin 300ms;
}

.template-add-container .anim-height {
  transition: height 300ms ease-in-out, margin 300ms;
}

.template-add-container .template-rows-0fr {
  grid-template-rows: 0fr;
}

.template-add-container .delay-overflow-visible {
  overflow: hidden;
  animation: overflowVisible 0ms ease-in-out 450ms forwards;
}
@keyframes overflowVisible {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

.page-height {
  height: calc(100% - 50px);
}

.pointer {
  cursor: pointer;
}

/* .template-add-container .btn-wrapper:hover,
.template-list-table .btn-wrapper:hover {
  background-color: #f3f3f3;
  cursor: pointer;
  border-radius: 2px;
} */
.template-list-table .bold-on-hover:hover {
  font-weight: 500;
}

.template-add-container .w-fit-content {
  width: fit-content;
}

.template-add-container .rotate-down {
  transform: rotate(0deg);
  animation: rotateDown 300ms ease-in-out;
}

.template-add-container .rotate-up {
  transform: rotate(180deg);
  animation: rotateUp 300ms ease-in-out;
}

@keyframes rotateDown {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotateUp {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.template-add-container .error-block {
  font-size: 13px;
  color: red;
  padding-top: 5px;
}

.template-add-container .rounder-grey-border {
  border: 0.5px solid #879096;
  border-radius: 4px;
  padding: 3px 5px;
}

.template-add-container .related-task-container {
  border-bottom: 1px solid #dedede;
}

/* RELATED TASK ADD MODAL */
.template-task-add-modal-container {
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-left: 25%;
}

.template-task-add-content {
  background-color: var(--bg-primary);
  /* padding: 30px 20px; */
  /* min-width: 100px; */
  height: fit-content;
  /* min-width: 650px; */
  width: min(95%, 461px);
}

.related-head-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 18px 10px 18px;
  position: relative;
  z-index: 99;
}

.related-top-box-shadow {
  box-shadow: 0 6px 5px -5px #091e4229;
}

.related-head-text {
  font-size: 16px;
  font-weight: 600;
}

.template-modal-content {
  --screen-height: 100vh;
  --top-padding: 30px;
  --button-space: 60px;
  --head-font-size: 18px;
  --offset-size: 120px;
  max-height: calc(
    var(--screen-height) - var(--top-padding) - var(--head-font-size) - var(--button-space) -
      var(--offset-size)
  );
  overflow-y: auto;
  padding: 0px 18px;
}

.template-add-container .modal-btn-container {
  padding: 18px;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--bg-primary);
}

.template-add-container .subtask-input {
  border: 0.5px solid #879096;
  border-radius: 3px;
  resize: none;
}

.template-add-container .template-tooltip-wrapper {
  position: static !important;
}

.template-add-container .left-18px {
  left: calc(18px + 1em);
}

.template-add-container .left-var-0 {
  --wrapper-left: 0px;
}

.template-add-container .left-36px {
  left: calc(36px + 2em);
}

.template-add-container .left-72px {
  left: calc(72px + 3em);
}
.template-add-container .left-108px {
  left: calc(108px + 4em);
}

.template-list-table th:nth-child(3),
.template-list-table td:nth-child(3) {
  text-align: center;
}
.template-list-table th:nth-child(7),
.template-list-table td:nth-child(7) {
  text-align: center;
}

.naked-btn {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  color: black;
}

.number-input-template {
  height: 38px;
  padding: 11px 15px;
  border: 0.5px solid #879096;
  border-radius: 3px;
}

.template-root .number-input-template,
.template-add-container .number-input-template {
  width: 8.5ch !important;
}

form .default-input {
  height: 38px;
  padding: 11px 15px;
  border: 0.5px solid #879096;
  border-radius: 3px;
}

/* form .default-select {
  padding-inline: 5px;
  border: 0.5px solid #879096;
  border-radius: 3px;
} */

.template-add-container .related-task-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.template-add-container .mw-100 {
  max-width: 100%;
}

.template-add-container .editor-style {
  min-height: 80px;
  max-height: 200px;
  line-height: 1.2;
}

.template-add-container .template-editor-toolbar {
  border: none;
}

.template-add-container .template-wrapper-style {
  border: 0.5px solid var(--border-color, #87909e);
  border-radius: 3px;
}

.template-wrapper-style:has(div[contenteditable='true']:focus) {
  border: 1px solid var(--Base-Color, #3454d1);
}

.template-add-container input:has(+ .error-block) {
  outline: 1px solid red !important;
  padding-inline: 5px !important;
}

.rs-menu {
  transform: translateZ(10px);
  z-index: 99999;
}

.template-add-container .col:has(.error-block) input {
  border: 1px solid red;
}

.template-add-container .small-gret-text {
  color: grey;
  font-size: 12px;
}
.select-info:hover div {
  display: block !important;
}

.select-info div {
  position: absolute;
  /* min-width: 150px; */
  width: fit-content;
  background-color: #00000024;
  top: 0;
  padding: 2px 10px;
  left: calc(100% + 10px);
  font-size: 12px;
  z-index: 99999;
  border-radius: 23px;
  white-space: nowrap;
}

.left-stack {
  left: -20px;
  position: relative;
}

.template-add-container .ml-neg-15 {
  margin: auto;
  margin-left: -15px !important;
}

.template-add-container .member-name-container {
  display: grid !important;
  min-width: max-content;

  bottom: calc(100% + 3px);
  position: absolute;
  align-items: center;
  grid-template-columns: 0fr;
  align-self: center;
  bottom: calc(100% + 5px);
  background-color: #282828;
  font-weight: 500;
  color: var(--bg-primary);
  width: -moz-fit-content;
  height: fit-content;
  width: fit-content;
  font-size: 12px;
  border-radius: 3px;
}

.avatar-container {
  display: flex;
}

/* .template-add-container .member-name {
  position: absolute;
  display: none;
  bottom: calc(100% + 5px);
  background-color: var(--bg-primary);
  width: fit-content;
  padding: 2px;
  font-size: 12px;
} */

.member-avatar {
  outline: 2px solid var(--bg-primary);
}

.member-avatar:hover {
  outline: 2px solid var(--bg-primary);
  z-index: 10;
  position: relative;
  cursor: pointer;
}

.l-n20px {
  left: -20px;
}

.avatar-tooltip-bottom {
  bottom: -30px;
  z-index: 999;
}
.avatar-tooltip-top {
  bottom: calc(100% + 5px);
}

.fade-in {
  opacity: 0;
  animation: fadeInTemp 200ms ease-in-out 100ms forwards;
}

@keyframes fadeInTemp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.font-13 {
  font-size: 13px;
  font-weight: 500;
}
.font-14 {
  font-size: 14px;
  font-weight: 500;
}

.template-sub-container {
  /* border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 10px; */
  /* width: 90%; */
  /* border: 1px solid var(--border-color); F */
  /* padding: 20px; */
  border-radius: 10px;
  /* width: 90%;*/
}

.template_search_input_contain {
  border: 1px solid #d1d1d1;
  padding: 5px 18px 5px 5px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  gap: 5px;
  font-size: 13px;
  min-height: 34px;
  width: 180px;
}
.template_search_input_contain:focus {
  border: 2px solid #3454d1;
  outline: none; /* Optional: removes the default outline */
  box-shadow: none;
}
.add_item_icon_bg {
  font-size: 14px;
  color: var(--second-text-color);
  border-radius: 5px;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border: none;
}
.add_item_icon_bg_active {
  font-size: 14px;
  background-color: var(--btn-hover);
  color: #3454d1;
  border-radius: 5px;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border: none;
}
.add_item_icon_bg:hover {
  background-color: #f3f5f7;
}
.template-table-container {
  /* max-height: 52vh; */
  min-height: 52vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  border-radius: 4px 4px 0px 0px;
  background: var(--bg-primary);
}
.template-img-icon-size {
  width: 13px;
  height: 15px;
  margin-right: 6px;
}

.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-primary);
  border-radius: 6px;
  height: 25px;
  width: 30px;
  border: 1px solid #0c0b0b14;
  color: grey;
  cursor: pointer;
}
tr:hover .icon-div {
  visibility: visible !important;
}

.nowrap {
  white-space: nowrap;
}

/* TEMPLATE MODAL DESIGN */
.template-modal-root-container {
  height: 100vh;
  width: 100vw;
  background: var(--modal-bg, rgba(15, 15, 15, 0.4));
  position: fixed;
  z-index: 4;
}

.template-modal-form {
  min-height: 70vh;
  border-radius: 10px;
  width: 70vw;
  background: var(--bg-primary);
  margin: 20px 0;

  /* margin-top: 50px; */
}

.bare-input {
  border: none !important;
  outline: 0px !important;
  background-color: transparent;
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  height: auto !important;
}

.bare-input:focus {
  border: none !important;
  /* outline: 1px solid #3454d1 !important; */
  /* padding-inline: 5px !important; */
  /* transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  transition-property: padding, outline;
  background-color: transparent; */
  /* outline: none !important; */
}

.bare-input-container {
  position: relative;
}

.bare-input-container:hover .bare-input:not(:focus) + .input-wrapper {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.bare-input:focus + .input-wrapper {
  opacity: 1;
  background-color: transparent;
  /* outline: 1px solid #3454d1; */
  border-radius: 3px;
  /* transition: outline 200ms ease-in-out; */
}

.bare-input-container .input-wrapper {
  content: '';
  position: absolute;
  width: calc(100% - 5px);
  height: calc(100% + 10px);
  background-color: #091e420f;
  padding: 0px;
  inset: 0;
  left: 5px;
  top: -5px;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
}

.template-add-container .mt-18px {
  margin-top: 18px;
}

/* .bare-input::placeholder {
  color: var(--main-text-color, #2a2e34) !important;
} */

/* .bare-input:not(:focus):hover {
  background-color: #091e420f;
} */

.temp-border-top {
  border-top: 0.5px solid var(--border-color);
}

.template-checkbox {
  width: 14px;
  height: 14px;
}

.template-task-add-modal-container {
  padding-top: 20px;
  height: 100vh;
  max-height: 100vh;
}

.template-task-add-content {
  border-radius: 10px;
  overflow: hidden;
}

.related-task-preview-head:hover {
  background-color: rgba(245, 245, 245, 0.497);
}
.related-task-expanded-grid {
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr 1fr;
  row-gap: 1rem;
  padding: 15px 0px;
  align-items: center;
}
.temp-subtask-prev {
  display: none !important;
}

.subtask-prev-container:hover .temp-subtask-prev {
  display: flex !important;
}

.subtask-prev-container {
  padding: 3px;
  border-radius: 4px;
  outline-width: 1px;
  outline-style: solid;
  outline-color: transparent;
}

.subtask-prev-container:hover {
  outline-color: var(--divider, #f1f1f1);
  background: #f9fafa;
}

.addon-settings-container {
  position: absolute;
  border-radius: 10px;
  right: 0px;
  top: calc(100% + 7px);
  background-color: var(--bg-primary);
  z-index: 99999;
  padding: 20px;
  width: max-content;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: default;
}

.temp-border-button {
  padding: 5.2px 11px;
  border-radius: 5px;
  border: 0.5px solid var(--border-color);
}

.light-temp-border {
  border: 0.5px solid var(--border-color);
}

.dark-border {
  /* border-color: black !important; */
  border-width: 2px;
}

.template-add-container .default-select,
.template-root .default-select {
  min-height: 34px !important;
}

.template-list-container {
  padding: var(--top-breadcrumb-padding);
}
@media (max-width: 768px) {
  .template-list-container {
    padding: 0px;
  }
  .template-sub-container {
    /* padding: var(--top-breadcrumb-padding-responsive); */
    padding: 0px;
  }
  .template-table-container {
    max-height: 65vh;
    margin-top: 188px;
    padding: 0px 17px;
  }
  .template_search_input_contain {
    width: 100%;
  }
  .template-container-horizontal-padding {
    margin-top: 47px;
    z-index: 9 !important;
  }
}

.related-task-name-block {
  color: var(--main-text-color);
  border: 1px solid var(--btn-border);
  padding: 0 10px;
  border-radius: 4px;
  text-overflow: ellipsis;
  font-size: 11px;
  /* margin-left: 5px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.template-related-task-container {
  max-height: var(--page-height);
  overflow: auto;
}

.template-confirmation-description {
  padding: 15px 10px 16px 10px;
  text-align: center;
  /* padding: 0 20px 20px 20px; */
  color: rgb(79, 78, 78);
  font-size: 14px;
}
