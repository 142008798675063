:root {
  /* Colors */
  --accent-color: rgba(52, 84, 209, 1); /* Primary Color;  */
  --color-light-blue: #2196f3;
  /* --accent-color: linear-gradient(93deg, #56bffa 6.68%, #ec97f4 146.67%); Primary Color;  */
  --accent-bg-color: #e9f2ff; /*Used as the background for accent text*/

  --modal-bg: rgba(0, 0, 0, 0.2);
  --sidebar-bg: rgba(247, 248, 249, 1);
  --sidebar-hover: rgba(232, 234, 237, 1);
  --input-border: rgb(192, 192, 192);

  --input-border-shadow: rgb(238, 239, 241) 0px 0px 0px 1px inset;
  --box-border-shadow: rgb(238, 239, 241) 0px 0px 0px 1px inset;

  --border-color: rgba(241, 241, 241, 1);
  --alert-bg: #fff;
  --btn-bg: rgba(234, 240, 246, 1);
  --btn-bg-light: #ffffff;
  --btn-hover: #fbfbfb;
  --btn-border: rgba(203, 214, 226, 1);
  --btn-active: #fbfbfb;
  --btn-text-color: #212529;
  --btn-text-light: #8f8f8f;
  --button-shadow: 0px 0px 2px #e0e0e0, 0px 1px 4px -2px rgba(24, 39, 75, 0.02),
    0px 4px 4px -2px rgba(24, 39, 75, 0.06);

  --divider: rgba(241, 241, 241, 1);
  --divider-dark: #dddddd;

  --bg-light: #f9f9f9;
  --bg-light-gray: #f3f5f7;
  --bg-dark: rgba(0, 0, 0, 1);
  --main-gray: rgb(90, 90, 90);
  --bg-primary: #ffffff;

  --icon-light: #2a2e34;

  /* --Base-Color: linear-gradient(93deg, #41a0d7 6.68%, #e388ec 146.67%); */

  --Base-Color: #114dff;
  --base-color-light: rgb(46 201 252);

  --hover-verify: rgb(179, 255, 151);
  --hover: rgba(234, 235, 239, 1);
  --hover-2: rgba(242, 243, 248, 1);
  --color-red: rgb(234, 77, 77);

  /* Heights and Widths */
  --top-bar-height: 55px;

  /* Texts */
  --main-text-color: rgba(42, 46, 52, 1);
  --text-color: rgba(84, 98, 114, 1);

  --common-description-text: #87909e;
  --second-text-color: #87909e;
  ---text-dark: var(--text-dark);
  --text-grey: rgba(135, 144, 158, 1);
  --text-active: #212529;
  --text-inactive: #abaaaa;

  /* selection colors */
  --option-selected: #2684ff;

  /* Paddings */
  --top-bar-padding-y: 15px; /* Top section padding in y-direction */
  --top-bar-padding-x: 24px; /* Top section padding in x-direction */
  --top-breadcrumb-padding-y: 8px; /* Top section padding in y-direction */
  --top-breadcrumb-padding-x: 24px; /* Top section padding in x-direction */
  --top-breadcrumb-padding-y-responsive: 0px; /* Top section padding in y-direction */
  --top-breadcrumb-padding-x-responsive: 14px; /* Top section padding in y-direction */
  --top-bar-padding: var(--top-bar-padding-y) var(--top-bar-padding-x); /* Top section padding */
  --top-breadcrumb-padding: var(--top-breadcrumb-padding-y) var(--top-breadcrumb-padding-x); /* Top breadcrumb padding */
  --top-breadcrumb-padding-responsive: var(--top-breadcrumb-padding-y-responsive)
    var(--top-breadcrumb-padding-x-responsive); /* Top section padding */

  --top-task-tab-padding-y: 0px; /* Top section padding in y-direction */
  --top-task-tab-padding-x: 24px; /* Top section padding in x-direction */
  --top-task-tab-padding: var(--top-task-tab-padding-y) var(--top-task-tab-padding-x); /* Top breadcrumb padding */

  --login-top-bar-padding: 15px 25px 15px 90px;
  --sidebar-item-padding-x: 17px;
  /* --sidebar-item-padding-y: 10px;. */
  --sidebar-item-padding-y: 8px;
  --sidebar-item-padding: var(--sidebar-item-padding-y) var(--sidebar-item-padding-x);
  --app-logo-left-padding: var(--top-bar-padding-y) calc(10px + var(--sidebar-item-padding-x));

  /*  font size */
  --regular-font: 13px;
  --small-font: 12px;
  --xxl-font: 18px;

  /* sidebar */
  --active-tab-font-size: 13px;
  --active-tab-font-weight: 600;
  --in-active-tab-font-weight: 500;
  --drop-down-shadow: rgba(28, 40, 64, 0.04) 0px 0px 0px 1px,
    rgba(28, 40, 64, 0.12) 0px 4px 8px -4px, rgba(28, 40, 64, 0.16) 0px 4px 12px -2px;
  --link-text: #114dff;
  --border-radius: 8px;
  --common-text-color: #f1f1f1;
  --dark-mode: brightness(1);
  --tab-hover: #e9f2ff;
}
/* Dark mode */
[data-theme='dark'] {
  --bg-primary: #232529;
  --bg-light: #1b1d21;
  --sidebar-hover: rgba(0, 0, 0, 0.2);
  --bg-light-gray: #313337;
  --border-color: #313337;
  --alert-bg: #313337;
  --divider: #313337;
  --divider-dark: #313337;
  --btn-hover: #232529;
  --btn-active: #1a1a1a;
  --main-text-color: #dbdbdb;
  --btn-text-color: #eeeff1;
  --second-text-color: #dddddd;
  --btn-bg-light: #232529;
  --btn-hover: #313337;
  --icon-light: #eeeff1;
  --input-border-shadow: inset 0px 0px 0px 1px #313337;
  --link-text: #53bdeb;
  --text-active: #dbdbdb;
  --text-inactive: #868686;
  --dark-mode: invert(1);
  --tab-hover: #92b5e9;
}
body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 13px;
  color: var(--main-text-color);
  background: var(--bg-primary);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.w-70 {
  width: 70%;
}
body {
  background: var(--bg-light);
}
input {
  background: transparent;
  color: var(--main-text-color);
}

tr:hover {
  background: var(--bg-light-gray);
  color: var(--btn-text-color) !important;
}

tr.no-hover:hover {
  --bs-table-accent-bg: #fff !important;
  color: inherit !important;
}

/* This class is used in the content container to avoid unnecessary layout displacements. 
/* This will add vertical scroll if exceeds the content space */
.page-content-height {
  height: calc(100vh - var(--top-bar-height));
  /* scroll-snap-type: y proximity; */
  overflow: auto;
  background-color: var(--bg-light);
}

.breadcrumbs {
  position: sticky;
  top: 0;
  background: var(--bg-primary);
  padding: var(--top-breadcrumb-padding);
}
@media screen and (max-width: 768px) {
  .breadcrumbs-page {
    margin-top: 66px !important;
  }
}

::-webkit-scrollbar {
  height: 3px; /* Set height instead of width for horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1); /* Adjust scrollbar thumb color */
  border-radius: 3px; /* Add radius to scrollbar thumb */
}

::-webkit-scrollbar-track {
  background: transparent; /* Optional: style the track */
}
/*  */

@media screen and (max-width: 768px) {
  .breadcrumbs {
    overflow-x: scroll;
  }
}

/* @supports selector(:has(*)) {
  .page-content-height:has(.breadcrumbs) .breadcrumbs ~ * {
    height: calc(100vh - var(--top-bar-height) - 40px);
    overflow: auto;
  }

  .page-content-height:has(.breadcrumbs) {
    height: auto;
  }
} */
.grey-text {
  color: var(--text-grey);
}

/* Apply styles to all scrollbars */
::-webkit-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 var(--bg-light-gray); /* For Firefox */
}

#MODAL {
  position: relative;
  z-index: 99999;
}

.alert_view {
  /* background: linear-gradient(90deg, rgba(40, 37, 37, 1) 0%, rgba(130, 24, 79, 1) 100%); */
  background: linear-gradient(90deg, rgb(16 18 17) 0%, rgb(96 4 10) 100%);
  position: absolute;
  min-width: 180px;
  border-radius: 3px;
  bottom: 15vh;
  z-index: 5;
  left: 1vw;
  text-align: center;
  color: var(--bg-primary);
  padding: 5px 4px 5px 2px;
  font-size: 15px;
  z-index: 9999999;
}

#grad {
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
}

.rmv {
  user-select: none;
  cursor: pointer;
}
.model_contain {
  /* width: 100vw; */
  background: #ffffff99;
  position: fixed;
  z-index: 5;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(2px);

  /* height: 100%;
  width: 100%;
  background: rgba(15, 15, 15, 0.6);
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: center; */
}

.modal_view {
  border-radius: 2px;
  margin-top: 50px;
  border-radius: 16px;
  background: var(--bg-primary);
  box-shadow: rgba(28, 40, 64, 0.12) 0px 8px 28px -6px, rgba(28, 40, 64, 0.14) 0px 18px 88px -4px;
  z-index: 9999;
  will-change: transform, box-shadow;
  transform-origin: 50% center;
  padding: 3px;
}

.modal_view_inner {
  width: 100%;
  height: 100%;
  box-shadow: rgba(35, 37, 41, 0.07) 0px 0px 0px 1px;
  border-radius: 12px;
  overflow: hidden;
}
.model_contain .view-end .modal_view {
  margin-top: 0px;
  border-radius: 0px;
}
.modal_view_content {
  padding: 12px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}

.modal_right_side .modal_view {
  border-radius: 0px;
  margin: 0px;
}
.modal_right_side .modal_view_inner {
  border-radius: 0px;
}

.modal_right_side .modal_view_content {
  height: calc(100vh - 100px);
  max-height: 100%;
}

.modal_view_organization {
  min-height: fit-content;
  width: 35vw;
  background: var(--bg-primary);
  border-radius: 9px;
  margin-top: 50px;
}
.modal_view_file_activity {
  min-height: 500px;
  width: 35vw;
  background: var(--bg-primary);
  border-radius: 9px;
  margin-top: 50px;
  max-height: 600px;
}

.modal_view_folder_details {
  width: 30vw;
  height: fit-content;
  max-height: 650px;
  background: var(--bg-primary);
  margin-top: 50px;
  max-height: 600px;
}

.modal_view_file {
  min-height: 70vh;
  width: 75vw;
  background: var(--bg-primary);
  border-radius: 9px;
  margin-top: 50px;
}
.description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px 15px 0px;
}

.delete_modal_view {
  min-height: 35vh;
  width: 30vw;
  background: var(--bg-primary);
  border-radius: 9px;
  margin-top: 50px;
  position: relative;
  padding: 15px;
}

.modal_view_label {
  width: 25vw;
}
.modal_view_branch {
  min-height: fit-content;
  width: 25vw;
  background: var(--bg-primary);
  border-radius: 2px;
  margin-top: 50px;
  border-radius: 10px;
}
.modal_view_change_password {
  min-height: fit-content;
  width: 20vw;
  background: var(--bg-primary);
  border-radius: 2px;
  margin-top: 150px;
  border-radius: 10px;
}
.modal_view_milestone {
  min-height: fit-content;
  width: 25vw;
  background: var(--bg-primary);
  border-radius: 2px;
  margin-top: 50px;
  max-height: 650px;
  overflow: scroll;
  overflow-x: hidden;
  padding-bottom: 1rem;
}
.modal_view_inner {
  width: 100%;
  height: 100%;
}
.modal_footer {
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  min-height: 50px;
  background: var(--bg-light);
  border-top: 1px solid var(--bg-light-gray);
}
.modal_view_label_end {
  height: 100vh;
  width: 25vw;
  background-color: var(--bg-primary);
  border-left: 1px solid var(--border-color);

  box-shadow: rgba(28, 40, 64, 0.12) 0px 8px 28px -6px, rgba(28, 40, 64, 0.14) 0px 18px 88px -4px;

  overflow: hidden;
  z-index: 9999;
  will-change: transform, box-shadow;
  transform-origin: 50% center;
}
.view-end {
  display: flex;
  justify-content: flex-end;
}
.modal_view_content_end {
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px) !important;
  overflow-y: scroll;
}
.modal_end.modal_view {
  margin-top: 0px;
  border-radius: 0px;
}
.modal_end.modal_view .modal_view_inner {
  border-radius: 0px;
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.login_bt_contain {
  display: flex;
  justify-content: center;
}
.hr_center {
  display: flex;
  justify-content: center;
}

.help-block {
  color: red;
  font-size: 13px;
}
.alert_container {
  margin-top: 14px;
  padding: 7px;
}
.hr_right {
  display: flex;
  justify-content: flex-end;
}

.side_bar_contain {
  /* min-width: 230px;
  max-width: 230px; */
  max-height: 100svh;
  /* z-index: 2000; */

  /* overflow: auto; */
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  /* gap: 30px; */
  /* justify-content: space-between; */
  /* background-color: var(--sidebar-bg); */
  background-color: var(--bg-primary);
  padding-bottom: 3em;
  transition: all 0.3s;
  /* transition: max-width 0.5s ease-out; */
}

/* Hamburger Menu for Smaller Screens */
.hamburger {
  visibility: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
  z-index: 1100;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: var(--text-color);
  margin: 4px 0;
}

.overlay-container {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  z-index: 11;
}
.notification-header-wrap {
  position: relative;
}

@media (max-width: 768px) {
  .tab_contain {
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 79px;
    width: 100%;
    z-index: 10;
    padding: 17px;
    background: var(--bg-primary);
    box-shadow: 0px 3px 20px 0px var(--bg-light-gray);
  }
  .page-content-height {
    height: 100vh;
    overflow: inherit;
    scroll-snap-type: none;
    /* padding-bottom: 24px; */
    background: var(--bg-light);
    overflow-y: auto;
    overflow-x: clip;
  }

  .side_bar_contain.show {
    transform: translateX(0);
  }
  .side_bar_contain.hide {
    transform: translateX(-100%);
    position: absolute;
    z-index: 1000;
  }

  .side_bar_contain {
    position: fixed;
    z-index: 3000;
    height: 100%;
  }
  .task-list-container .table-container {
    background: transparent;
  }

  /* notification container */
  .notification-modal-container {
    width: 90%;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 24%);
    right: 0;
    left: 5%;
    top: calc(100% + 32px);
  }

  .notification-header-wrap {
    position: inherit;
  }
  .notification-header-wrap .clickable {
    position: inherit !important;
  }

  /* subtask-view icons visibility */
  .subtask-view-actions {
    visibility: visible !important;
  }
  /* reminder,subtask table parent class */
  .reminder-list-container {
    max-width: 100%;
  }

  /* task - components -single view */
  .task-heading-container {
    flex-wrap: wrap;
    padding: 10px 15px 15px 15px;
    flex-direction: column;
    align-items: normal;
  }
  .breadcrumbs {
    padding: 9px 17px;
    top: 54px;
  }
  .task-chat-container {
    border-left: 0.5px solid var(--border-color);
  }
  .task-add-body {
    flex-direction: column;
    width: 20em;
  }
  .table-header-breadcrumbs {
    top: 55px;
  }
  .template-container {
    display: flex;
    height: 30vh;
    overflow: visible;
    border-left: 0;
    max-width: 100%;
    padding: 0px;
  }

  .table-due-date {
    margin-left: 7px;
  }
  /* Default state, allow scrolling */
  .global_container {
    overflow-y: auto !important; /* or scroll, depending on your needs */
  }

  /* When the modal is active, prevent scrolling */
  body.modal-open .global_container {
    overflow-y: hidden;
  }
  .task-container {
    width: auto;
    min-height: auto;
  }
  .responsiveTable tbody tr {
    border: 1px solid #d2d2d2;
    padding: 0.25em;
  }
  .recurring-content {
    display: flex;
    flex-direction: column;
  }
  .left {
    width: auto;
  }
  /* .template-sub-container {
    width: 100% !important;
  } */

  /* table icon show */
  .icon-div {
    visibility: visible !important;
  }

  /* myteam-container */
  .myteam-table-container {
    width: 100% !important;
  }

  .reminder-task-name {
    display: none !important;
  }

  .hamburger {
    visibility: visible;
    position: fixed;
  }

  .outlet_contain {
    margin-left: 0;
    width: 100%;
  }
  .calendar-wrap .react-calender-scedule {
    display: inline-table !important;
  }

  /* calendar date-range */
  .rdrMonthPicker,
  .rdrYearPicker {
    margin: 0px 2px;
  }
  .rdrNextPrevButton {
    background-color: var(--bg-primary);
  }
  .modal_view_change_password {
    width: 90vw;
  }
}

.nav_item_contain {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  row-gap: 5px;
}
.loadingimg {
  display: flex;
  justify-content: center;
}
.spinner {
  animation: spin 1s linear infinite;
}
button .text-load,
[role='button'] .text-load {
  display: none;
}
/* Role button */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-icon {
  font-size: 13px;
}
.login_contain {
  display: flex;
  justify-content: center;
}

.company_icon_contain {
  background: var(--bg-primary);
  /* z-index: 10; */

  /* min-height: 85px; */
  /* border-bottom: 1px solid var(--border-color); */
  /* margin: 5px; */
  /* border-radius: 5px; */
  /* text-align: center; */
  /* padding: var(--app-logo-left-padding); */
  padding: 12px 22px;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  /* padding: 12px 11px 11px 0px; */
  /* padding: 8px 6px 8px 17px; */
  height: var(--top-bar-height);
  /* position: sticky; */
  /* top: 0px; */
  /* background: var(--sidebar-bg); */
}
.company_name {
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
}
.menu-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-color);
}

.nav-item-scroll {
  max-height: calc(100vh - var(--top-bar-height));
  overflow-y: hidden;
  scrollbar-gutter: stable;
  /* padding-top: 30px; */
}
.nav-item-scroll:hover {
  overflow-y: auto;
}

.company_icon_contain img {
  object-fit: contain;
  height: 100%;
  width: fit-content;
}

.header_contain {
  display: flex;
  min-height: 50px;
  height: var(--top-bar-height);
  justify-content: space-between;
  align-items: center;
  padding: var(--top-bar-padding);
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  background: var(--bg-primary);
  z-index: 10;
}
.header_slogan {
  font-size: 13px;
  font-weight: 500;
  color: var(--second-text-color);
}
@media screen and (max-width: 768px) {
  .header_slogan {
    display: none;
  }
  .header_contain {
    position: absolute;
    width: 100%;
    padding: 15px;
  }
  .url-container {
    position: absolute;
    width: 100%;
    top: 55px;
    z-index: 10;
  }
}
.name_abb {
  font-size: 13px;
  background: #d2d2d2;
  padding: 5px;
  border-radius: 26px;
  font-weight: 700;
}

.header_sub_contain {
  display: flex;
  gap: 17px;
  align-items: center;
  border-left: 1px solid var(--border-color);
  padding-left: 10px;
}
.header-menu-icon {
  color: var(--main-text-color);
  z-index: 99;
}

.create_contain {
  display: flex;
  gap: 5px;
  background: #d2d2d2;
  padding: 2px 16px;
  border-radius: 5px;
  align-items: center;
}

.custom_btn {
  border: none;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #d2d2d2;
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
}

.upload_loading_btn {
  border: none;

  border-radius: 5px;
  background-color: #d2d2d2;
  align-items: center;
  cursor: pointer;
  display: flex;

  padding: 4px 40px 4px 40px;
}

.load_contain {
  display: flex;
  align-items: center;
}

.button_txt {
  font-size: 13px;
  font-weight: 500;
}
.cancel-btn .button_txt {
  color: #9197a3;
}

.table_outer {
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--bg-primary);
}
.table-container {
  /* max-height: 52vh;
  min-height: 40vh;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  border-radius: 4px 4px 0px 0px;
  background: var(--bg-primary); */
  /* max-height: 60vh; */
  /* min-height: 40vh; */
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  border-radius: 4px 4px 0px 0px;
  background: var(--bg-primary);
  min-height: 30vh;
}

.add_txt {
  font-size: 13px;
}
.tb_lp {
  padding-left: 20px !important;
}

.create-btn {
  border: none;
  padding: 3px 15px;
  border-radius: 5px;
  background: var(--Base-Color);
  cursor: pointer;
  color: var(--bg-primary);
  display: flex;
  gap: 3px;
  align-items: center;
}

.table_contain {
  padding: 8px;
}

thead th {
  font-size: 13px;
  font-weight: 500;
}

tbody td {
  font-size: 13px;
  font-weight: 500;
}

.sub_padding {
  padding: 16px;
}
.heading {
  font-size: 17px;
  font-weight: 600;
  color: var(--main-text-color);
}
.tab_contain {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
}
.check:hover {
  opacity: 1;
  cursor: pointer;
}

/* css for delete-modal */

.delete_bin_head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 13px 10px 15px;
}

.delete_bin_image_container {
  border-radius: 50%;
  padding: 20px;
  background-color: var(--bg-light-gray);
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete_modal_title {
  text-align: center;
}
.delete_modal_note {
  text-align: center;
  padding: 0 20px 20px 20px;
  color: gray;
}

.delete_modal_button {
  display: flex;
  justify-content: flex-end;
  margin: 0 10px;
  gap: 10px;
}

.close_button_position {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* end  */

.menu_items {
  height: 26px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  color: var(--main-text-color, #2a2e34);
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.menu_popup {
  z-index: 9999;
  height: fit-content;
  min-width: 100px;
  background: var(--bg-primary);
  position: absolute;
  top: calc(100% + 5px);
  border-radius: 10px;
  border: none;
  padding: 10px 4px;
  box-shadow: 0px 0px 6px 1px var(--border-color);
  z-index: 3000;
}

.left-30px {
  left: 30px;
}

.right-30px {
  right: 30px;
}
.ellipsis_icon {
  cursor: pointer;
  width: fit-content;
  position: relative;
}

.ellipsis_icon:hover {
  border-radius: 8px;
  background-color: var(--border-color);
}

.menu_items:hover {
  background: var(--border-color);
}

/* width */

::-webkit-scrollbar {
  width: 2px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255, 0.3);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.zoom-in:hover {
  transform: scale(1.2);
  transition: transform 200ms;
}

.tooltip-container {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: 9999;
  top: calc(100% + 6px);
  left: 0;
  background-color: var(--bg-primary);
  padding: 9px 11px;
  border-radius: 9px;
  width: fit-content;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tooltip-title {
  font-size: 12px;
  color: #879096;
  font-weight: 500;
}

.tooltip-desc {
  font-size: 12px;
  color: var(--text-dark);
  font-weight: 500;
  min-width: max-content;
}

.tooltip-wrapper:hover .tooltip-container {
  display: block;
  animation: fadeIn 200ms ease-in-out 300ms forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.context-container {
  user-select: none;
}

.test-alert {
  background: var(--alert-bg) !important;
  color: var(--icon-light);
  box-shadow: rgb(0 0 0 / 14%) 0px 0px 7px 2px, rgb(0 0 0 / 3%) -2px 0px 12px 1px;
  padding: 10px 20px;
  border-radius: 0px;
}

.expand-collapse-button_side_bar_section {
  position: absolute;
  height: 16px;
  width: 16px;
  color: var(--bg-primary);
  display: flex;
  border: 1px solid var(--hover);
  border-radius: 20px;
  background: var(--bg-primary);
  align-items: center;
  justify-content: center;
  top: 2.5%;
  left: 13.9rem;
  cursor: pointer;
  z-index: 9999;
  transition: left 0.4s;
}
.button_side_bar_section {
  position: absolute;
  left: 1.5rem;
  z-index: 99;
  color: var(--bg-primary);
  background: black;
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 12px;
  top: -2px;
  display: none;
}
.button_side_bar_container {
  height: inherit;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expand-collapse-button_side_bar_section:hover .button_side_bar_section {
  display: block;
}
.collapsed {
  max-width: 5rem;
  min-width: 5rem;
}
.collapsed .tab-active-font {
  display: none;
}
.collapsed .pinned {
  display: none;
}
.collapsed .sub-menu-arrow {
  display: none;
}
.collapsed .expand-collapse-button_side_bar_section {
  left: 4.5rem;
}
.collapsed .non-active {
  justify-content: center;
}
.collapsed .active_tab {
  justify-content: center;
}
.collapsed .active_tab:hover .sidebar-tooltip-text,
.collapsed .non-active:hover .sidebar-tooltip-text,
.collapsed .custom_tab:hover .sidebar-tooltip-text,
.collapsed .active_tab_sub:hover .sidebar-tooltip-text,
.collapsed .non_active_sub:hover .sidebar-tooltip-text {
  display: block;
}
.collapsed .non_active_sub {
  padding: 8px 5px 8px 5px;
}
.sidebar-tooltip-text {
  position: absolute;
  left: 5rem;
  z-index: 99;
  color: var(--bg-primary);
  background: black;
  padding: 3px 5px;
  border-radius: 4px;
  display: none;
  font-size: 12px;
}
.ta-success {
  border-left: 5px solid #6aae64;
}

.ta-error {
  border-left: 5px solid #fd5d5d;
}
.selectable-context-menu {
  position: absolute;
  max-width: 200px;
  width: 200px;
  z-index: 999;
}
.has-error {
  border: 1px solid red !important;
  border-radius: 4px;
}
.client-error {
  border: 0.5px solid red !important;
  border-radius: 4px;
  height: auto;
}
/* Error state */
.login-has-error {
  border: 1px solid red !important;
  border-radius: 4px;
}

/* Focus state on input with error */
.login-has-error:focus {
  border: 1px solid red !important;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.15) !important; /* Light red shadow for error state */
}

.has-filled {
  border: 1px solid rgb(58, 240, 58) !important;
  border-radius: 4px;
}

.rounded-circle {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.drop-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.custom-upload-contain {
  /* cursor: pointer; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 13px 3px 8px 0px;
  border: 1px dashed #d2d2d2;
  padding: 3rem 5px 3rem 5px;
  border-radius: 5px;
}
/* HTML: <div class="loader"></div> */
.dot-loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #87909e 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.circle-loader {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #87909e 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #87909e);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s te linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

.current-task {
  border: 1px solid var(--border-color);
  padding: 3px 8px;
  font-size: 0.8125rem;
  max-width: 200px;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limits text to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.current-task:hover {
  border: 1px solid hsl(0, 0%, 80%);
}

.indeterminate-progress-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 3px;
  z-index: 99;
}

.indeterminate-progress-bar {
  width: 0;
  height: 100%;
  position: relative;
  background-color: var(--accent-color);
  border-radius: 5px;
  /* Only show global progress, if api delay is greater the 1sec */
  animation: indeterminate 1.5s ease-in-out 1s forwards infinite,
    tooDelay 100ms ease-in-out 20s forwards;
}

/* check */
@keyframes tooDelay {
  0% {
    background-color: var(--accent-color);
  }
  100% {
    background-color: orange;
  }
}

@keyframes indeterminate {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 30%;
    width: 50%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.sidebar-icon {
  width: 16px;
}

.sidebar-img-icon {
  filter: var(--dark-mode);
  height: 14px;
}

/* Tooltip container */
.sidebar-item-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip text */
.tooltip {
  visibility: hidden;
  background-color: #555;
  color: var(--bg-primary);
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip visibility on hover */
.sidebar-item-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Ibm  update  */

.menu svg {
  width: 16px;
  height: 16px;
}
.rdw-editor-toolbar {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
}

.common-table-container {
  max-width: 1100px;
  padding: 16px 24px;
  border-radius: var(--border-radius);
}
.common-table {
  max-height: 65vh;
}

@media (max-width: 768px) {
  .common-table-container {
    max-width: 100% !important;
    border: 0px;
    padding: 0px;
    height: 100vh;
    overflow: auto;
  }
  .common-table {
    margin-top: 185px;
    padding: 17px;
    position: relative;
    height: 74vh;
    padding-bottom: 22px;
  }
}
