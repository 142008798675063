.slider-container:hover .slider,
.slider-container:hover .progress-track {
  height: 6px;
}

.slider-container {
  height: fit-content;
}

.slider {
  position: relative;
  height: 3px;
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: height 100ms ease-in-out;
}

.slider-track {
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  width: 100%;
  transform: translateY(-50%);
  /* background-color: #aaa; */
}

.slider-progress {
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  width: 0;
  transform: translateY(-50%);
  background-color: #f00;
  transition: width 0.1s ease-in-out;
}

.slider-handle {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: var(--bg-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: left ease-in-out;
}

.slider-handle:hover {
  transform: translate(-50%, -50%) scale(1.5);
}

.progress-track {
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  border-radius: 5px;
  height: 3px;
}
