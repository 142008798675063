.sidebar {
  height: 100vh;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  /* padding: 24px; */
  gap: 2px;
  transition: all 0.3s;
  position: relative;
  border-right: 1px solid var(--border-color);
  max-width: 15rem;
}
.menu ul {
  margin: 5px -16px;
  width: 100%;
}
.main-menu-list {
  height: 70vh;
  overflow: auto;
}
.menu ul li {
  position: relative;
  list-style: none;
}
.menu ul li a,
.menu ul li label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-dark);
  text-decoration: none;
  padding: 6px 10px;
  border-radius: 8px;
  max-width: 15rem;
  transition: all 0.3s;
}
.menu ul .pinned-menu-list li a {
  padding: 3px 10px;
}
.menu ul .pinned-menu-list li {
  margin: 0;
}
.menu ul li .text {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu ul li a:hover,
.menu ul li:active a {
  color: var(--btn-primary);
  background-color: var(--sidebar-hover);
}

.menu .sub-menu {
  display: none;
  margin-left: 20px;
  padding-left: 20px;
  padding-top: 10px;
  border-left: 1px solid var(--border-color);
}
.menu .sub-menu li a {
  padding: 10px 8px;
  font-size: 12px;
}

.active_tab .sub-menu {
  display: block;
}
.sidebar.active {
  min-width: 5rem;
  max-width: 5rem;
}

.sidebar.active .user-details {
  display: none;
}
.sidebar.active .menu .title {
  text-align: center;
}
.sidebar.active .menu ul li .arrow {
  display: none;
}
.sidebar.active .menu > ul > li > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar.active .menu > ul > li > a .text {
  position: absolute;
  left: 70px;
  top: 50%;
  padding: 5px 10px;
  transform: translateY(-50%);
  border-radius: 4px;
  color: var(--bg-primary);
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 99;
  width: max-content;
  overflow: visible;
}
.sidebar.active .menu > ul > li > a .text:after {
  content: '';
  position: absolute;
  left: -4px;
  top: 20%;
  height: 15px;
  width: 15px;
  border-radius: 2px;
  background-color: #000;
  transform: rotate(45deg);
  z-index: -1;
}
.sidebar.active .menu > ul > li > a:hover .text {
  left: 50px;
  opacity: 1;
  visibility: visible;
  font-size: 12px;
  color: var(--common-text-color);
}

/* label */
.sidebar.active .menu ul li .sub-menu-arrow {
  display: none;
}
.sidebar.active .menu > ul > li > div > label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar.active .menu > ul > li > div > label .text {
  position: absolute;
  left: 70px;
  top: 50%;
  padding: 5px 10px;
  transform: translateY(-50%);
  border-radius: 4px;
  color: var(--bg-primary);
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 99;
  width: max-content;
}
.sidebar.active .menu > ul > li > div > label .text:after {
  content: '';
  position: absolute;
  left: -4px;
  top: 20%;
  height: 15px;
  width: 15px;
  border-radius: 2px;
  background-color: #000;
  transform: rotate(45deg);
  z-index: -1;
}
.sidebar.active .menu .sub-menu-container {
  display: none;
  position: absolute;
  top: 0;
  left: 20px;
  width: 11rem;
  font-size: 12px;
  border-radius: 7px;
  padding: 5px;
  /* border: 1px solid #e8eaed; */
  background-color: var(--bg-primary);
  z-index: 99;
  box-shadow: var(--button-shadow, 0px 2px 4px rgba(0, 0, 0, 0.1));
}
.sidebar.active .menu .sub-menu-container .active_tab .text,
.sidebar.active .menu .sub-menu-container .non-active .text {
  font-size: 12px;
}
.sidebar.active .menu > ul > li > div:hover .sub-menu-container {
  display: block;
}
.menu-btn {
  position: absolute;
  right: -14px;
  top: 2%;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #757575;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  z-index: 99;
}
.menu-btn:hover {
  color: #000;
}
.pinned-menu-list {
  height: 125px;
  overflow: scroll;
}
.menu-end-container {
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding-bottom: 10px;
  background: var(--btn-bg-light);
}

.sidebar.active .header-menu-icon {
  z-index: 9;
}

.sidebar-items{
  overflow-y: scroll;
}
.active .sidebar-items{
  overflow: visible;
}

.active .pinned-menu-item{
  background-color: var(--sidebar-hover, #e5e5e5);
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .menu-btn {
    display: none;
  }
  .menu {
    overflow-y: scroll;
    height: 80vh;
  }
  .menu-end-container .menu {
    height: auto;
  }
}
