@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.recurrence-container {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

.recurring-x-padding {
  padding-inline: 25px;
}

.content-padding {
  padding-bottom: 25px;
  padding-top: 20px;
}

.radio-container {
  user-select: none;
}

.pr-14 {
  padding-right: 14px;
}

.pr-7 {
  padding-right: 7px;
}

/* .half{
  width: 50%;
} */

.mt-20 {
  margin-top: 20px;
}

.recur-drop-down {
  /* margin-left: 8px; */
  border-radius: 3px;
  padding: 3px 6px;
  /* border: 1px solid #8f989e; */
}

.left {
  width: 44%;
  border-right: 1px solid #d2d2d2;
}

.right {
  width: 56%;
}

.font-normal-500 {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  color: var(--main-text-color, #2a2e34);
}

.recurrence-container .modal-header {
  padding: 10px 25px;
  align-items: center;
}

.recurrence-heading {
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--main-text-color, #2a2e34);
}

.btnDay {
  display: inline-block;
  text-decoration: none;
  border: 1px solid var(--divider, #f1f1f1);
  border-radius: 50%; /* Makes it round */
  width: 30px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
  background: var(--bg-primary);
  text-align: center; /* Centers content horizontally */
  margin-left: 4px; /* Adjust as needed */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}

.recurrence-container select {
  padding: 5px 7px !important;
}

.recurrence-container input[type='date'],
.recurrence-container select {
  padding: 3px 6px;
  border-radius: 8px;
  background-color: var(--bg-primary);
  color: #7e7e7e;
  border: 1px solid var(--border-color);
}

.btnDay.active {
  border: 1px solid var(--divider, #f1f1f1) !important;
  color: #2a2e34 !important;
  background-color: var(--hover, #eaebef) !important;
}

.recur-frequency-select {
  margin-left: 8px;
  border-radius: 3px;
  padding: 3px 6px;
  /* border: 1px solid #d6d6d6; */
}

.recur-interval {
  min-width: 2ch;
  max-width: 5ch;
  border-radius: 8px;
  border: 1px solid var(--border-color, #d6d6d6);
  padding: 4px 0px;
}
.number-input-sm {
  min-width: 2ch;
  max-width: 8ch;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 4px 0px 4px 4px;
}

.recurrence-container input[type='number']::-webkit-inner-spin-button,
.recurrence-container input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.btn-ok {
  cursor: pointer;
  padding: 3px 9px;
  border-radius: 2px;
}
.btn-cancel {
  cursor: pointer;
  padding: 3px 9px;
  border-radius: 2px;
}

.recur-fu-modal input[type='radio'] {
  width: 18px;
  height: 18px;
}

.gap-10px {
  gap: 10px;
}

.recur-fu-modal .btn-ok:hover,
.recur-fu-modal .btn-cancel:hover {
  background-color: #edededb3;
}

.grey-btn {
  background-color: #edededb3 !important;
  padding: 5px 10px !important;
  cursor: pointer;
  border-radius: 3px;
}

.recur-dropdown {
  background-color: #edededb3 !important;
  padding: 5px 10px !important;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}

.grey-btn:hover,
.recur-dropdown:hover {
  background-color: #dadada !important;
}

.recur-fu-modal .heading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.recur-fu-modal .user-select-none {
  user-select: none;
}

.recur-fu-modal .vd__sc-modal-content {
  width: auto;
  padding: 20px !important;
  max-width: 448px !important;
  min-width: 320px !important;
  color: #000;
  font-size: 14px;
}

.recur-modal-content {
  max-height: 100%;
  overflow: auto;
  background-color: var(--bg-primary);
  border-radius: 8px;
}

.recurrence-container .start-date {
  --start-date-color: rgb(135 223 135);
  /* background-color: var(--start-date-color); */
  color: var(--start-date-color);
  border-radius: 50%;
  border: 1px solid var(--start-date-color);
  /* color: var(--bg-primary); */
}
.recurrence-container .end-date {
  --end-date-color: #ffdfdf;
  background-color: var(--end-date-color);
  border-radius: 50%;
  border: 1px solid var(--end-date-color);
  /* color: var(--bg-primary); */
}

.recurrence-container a {
  text-decoration: none;
  color: var(--main-text-color, #2a2e34);
}

.recurring-wrapper .btn-wrapper {
  --wrapper-left: -5px;
  color: black;
  user-select: none;
  position: relative;
  cursor: pointer;
}

.recurring-wrapper .fz-15px {
  font-size: 15px;
}
.recurring-wrapper .fz-14px {
  font-size: 14px;
}
.recurring-wrapper .grey-text {
  color: #879096;
}

.recurring-wrapper .btn-wrapper:hover::before {
  content: '';
  background-color: #f3f3f3;
  cursor: pointer;
  position: absolute;
  height: 100%;
  inset: 0;
  left: var(--wrapper-left);
  border-radius: 2px;
  z-index: -1;
}

.grey-circular-bg {
  background-color: var(--bg-light-gray);
  padding: 10px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

@media (max-width: 768px) {
  .recurring-button {
    position: absolute;
    z-index: 99;
    bottom: 25px;
    right: 25px;
  }
}
