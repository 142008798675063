.milestone-wrapper-style {
  border: 1px solid var(--border-color);
  padding: 5px;
  border-radius: 4px;
  font-size: 13px;
  cursor: text;
}

.milestone-editor-style .public-DraftStyleDefault-block {
  margin: 0;
}

/* .milestone-editor-style {
  line-height: 5px;
} */
.milestone-wrapper-style .DraftEditor-editorContainer {
  height: 250px;
}
.milestone_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px 10px 15px;
  position: sticky;
  top: 0;
  background: var(--bg-primary);
  z-index: 100;
}

.button_container {
  z-index: 100;
  position: sticky;
  bottom: 0;
  background: var(--bg-primary);
}

.action-button-start {
  display: flex;
  gap: 1px;
  background-color: var(--text-dark);
  border-radius: 8px;
  width: fit-content;
  border: 0px;
  padding: 3px 10px;
  color: var(--bg-primary);
}

.action-button-complete {
  height: 35px;
  width: 79px;
  color: var(--icon-light);
  border-radius: 4px;
  border: 0px;
  display: flex;
  gap: 3px;
  justify-content: center;

  align-items: center;
}

.table-row-hover:hover {
  background-color: rgba(234, 235, 239, 1);
}

.dot-icon {
  width: 8px;
  height: 8px;
  border-radius: 100px;
}

.milestone-status-container {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  padding: 2px 6px;
  width: fit-content;
}

.form-head {
  color: var(--text-dark);

  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.milestone-name-input {
  min-height: 30px !important;
  max-height: 70px !important;
  overflow-y: auto;
  font-size: 16px;
}

.milestone-txt {
  font-size: 14px;
  font-weight: 500;
}

.milestone-add-container {
  min-height: 40vh;
  min-width: 30vw;
  border-radius: 10px;
  margin-top: 50px;
  background: var(--bg-primary);
  /* padding: 20px; */
}
.milestone-name{
  max-width: 20vw;
}
@media (max-width: 768px) {
  .milestone-name{
    max-width: 80vw;
  }
}