.note-editor-container {
  display: flex;
  height: 100%;
  background-color: var(--bg-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  border-radius: 10px;
}

.note-list {
  width: 25%;
  background-color: var(--bg-primary);
  border-right: 1px solid var(--border-color);
  /* padding: 20px; */
  overflow-y: auto;
  transition: transform 0.4s ease, opacity 0.4s ease, width 0.4s ease;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.note-list.hidden {
  transform: translateX(-100%);
  opacity: 0;
  width: 0;
}

.note-list.visible {
  transform: translateX(0);
  opacity: 1;
  width: 20%;
}

.note-list h2 {
  margin-top: 0;
  font-size: 1.2em;
  color: #333;
  padding: 5px;
}

.note-list ul {
  list-style-type: none;
  padding: 0;
}

.note-list li {
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.note-list li:hover {
  background-color: var(--border-color);
}

.note-list li button {
  margin-left: 10px;
  background: none;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
  font-size: 0.9em;
}

.editor {
  width: 80%;
  padding: 12px 12px 10px 15px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.editor.full-width {
  width: 100%;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  /* margin-bottom: 10px; */
  align-self: flex-start;
}

.heading-input {
  margin: 10px 0px;
  padding: 10px;
  font-size: 14px;
  outline: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.editor-content-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--bg-primary);
  overflow: hidden;
}

.toolbar {
  display: flex;
  padding: 4px 4px 4px 4px;
  gap: 10px;
  /* padding: 10px; */
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-light-gray);
  padding-left: 5px;
}

.toolbar button {
  padding: 2px 8px 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.toolbar button:hover {
  background-color: #e0e0e0;
}

.toolbar button.active {
  background-color: #b0e4cc;
}

.editor-content-container {
  border: 1px solid var(--border-color);
}

.editor-content {
  padding: 10px;
  min-height: 400px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  flex-grow: 1;
  outline: none;
}

.save-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: var(--bg-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.save-button:hover {
  background-color: #45a049;
}

.note-modal {
  min-height: 90vh;
  max-height: 90vh;
  width: 85%;
  background: var(--bg-primary);
  border-radius: 10px;
  margin-top: 5vh;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: var(--bg-primary); /* Match this with your note list background color */
  z-index: 1;
  padding-bottom: 10px;
  padding: 10px 10px 5px 10px;
}
.save-btn {
  font-size: 14px;
  /* font-weight: bold; */
  background: black;
  color: var(--bg-primary);
  padding: 3px 13px 3px 13px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-heading {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.note-list .toggle-button {
  display: none;
}
.note-item {
  position: relative; /* Ensure the button is positioned relative to the li */
}
.delete-button {
  display: none;
  /* position: absolute; */
  /* right: 10px; Adjust as needed */
  /* top: 50%; Center vertically */
  /* margin-top: 10px; */
  /* transform: translateY(-50%); Center vertically */
}

.note-item:hover .delete-button {
  display: block; /* Show the button on hover */
}

.icon-container:hover svg {
  border: 1px solid black; /* Add a small border */
  border-radius: 4px; /* Optional: Add rounded corners */
  padding: 2px; /* Optional: Add some padding */
}

@media (max-width: 768px) {
  .note-list.visible {
    width: 100%;
    border-radius: 10px;
  }
  .editor.hidden {
    display: none;
  }
  .note-list .toggle-button {
    display: block;
  }
  .toggle-button {
    display: flex !important;
    align-items: center;
  }
  .note-modal {
    min-height: 87vh;
    max-height: 87vh;
  }
}

.deleted-notes {
  padding: 0px 5px 4px;
  background: #e9f2ff;
  border-radius: 4px;
}
