.invite-view-center {
  display: flex;
  justify-content: flex-end;
}

.invite-modal-view {
  min-height: 100vh;
  width: 25vw;
  background: var(--bg-primary);
}
.invite-head {
  font-weight: 600;
}

.invite-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 16px;
}
.invite-form-body {
  margin: 0 16px;
  flex: 1;
}

@media (max-width: 520px) {
  .invite-modal-view {
    width: 100vw;
  }
}

@media (min-width: 520px) and (max-width: 768px) {
  .invite-modal-view {
    width: 35vw;
  }
}

.invite-accept-body {
  display: flex;
  justify-content: center;
  align-items: center;
  --top-height: 55px;
  height: calc(100vh - var(--top-height));
}

.invite-accept-box {
  width: 35%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 5px 0px;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.invite-greeting {
  font-size: 20px;
  font-weight: 600;
}

.invite-border-bottom {
  border-bottom: solid 1px var(--border-color);
}

.invite-login-page {
  width: 25%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 5px 0px;
  border-radius: 4px;
  padding: 16px;
  /* display: flex;
  gap: 10px;
  flex-direction: column; */
}

.invite-sign-up-page {
  width: 32%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 5px 0px;
  border-radius: 4px;
  padding: 16px;
  /* display: flex;
  gap: 10px;
  flex-direction: column; */
}

.padding-left-5 {
  padding-left: 5px;
}
.padding-right-5 {
  padding-right: 5px;
}
@media (max-width: 720px) {
  .invite-sign-up-page {
    width: 45%;
  }
  .invite-login-page {
    width: 60%;
  }
  .invite-accept-box {
    width: 60%;
  }
}

@media (max-width: 420px) {
  .invite-sign-up-page {
    width: 45%;
  }
  .invite-accept-box {
    width: 90%;
  }
}

.invite-company-icon {
  /* background: var(--bg-primary); */

  /* padding: var(--app-logo-left-padding); */
  height: var(--top-height);
}

.invite-company-icon img {
  object-fit: contain;
  height: 100%;
  width: fit-content;
}
