.invoice-form-section {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.grid-2-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.items-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

/* .items-table th,
  .items-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
   */
/* .items-table th {
    background-color: #f8f9fa;
    color: #333;
    font-size: 0.9rem;
  }
   */
.vat-select {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-item-btn {
  background: none;
  border: 1px dashed #666;
  color: #666;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
}

.notes-textarea {
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.total-section {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.total-row {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  color: #666;
}

.grand-total {
  font-weight: bold;
  color: #333;
  border-top: 1px solid #ddd;
  padding-top: 8px;
}
/*   
  .file-upload-box {
    border: 2px dashed #ddd;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    color: #666;
  } */

/* Responsive Design */
@media (max-width: 768px) {
  .grid-3-col,
  .grid-2-col {
    grid-template-columns: 1fr;
  }

  /* .items-table {
      display: block;
      overflow-x: auto;
    } */

  .invoice-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .vat-select {
    font-size: 0.8rem;
  }

  /* .items-table th,
    .items-table td {
      padding: 8px;
    } */
}

.item-selected {
  background-color: var(--border-color);
}

.invoice-list-single {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px;
}

.invoice-list-single:hover {
  border-color: var(--Base-Color);
  background-color: rgba(243, 246, 255, 1);
}

.payment-table-container {
  border-radius: 4px 4px 0px 0px;
  /* max-width: 1100px; */
  padding: 16px 24px;
  border-radius: 4px;
}

.payment_received_view {
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.payment-inner-modal-view {
  width: 100%;
  height: 100%;
  box-shadow: rgba(35, 37, 41, 0.07) 0px 0px 0px 1px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.payment-modal-container {
  padding: 12px;
  /* max-height: calc(100vh - 200px); */
  overflow-y: scroll;
}

.payment-input-box {
  width: 100%;
  border: none;
  border-radius: 8px;
  height: 28px;
  padding: 2px 5px 2px 10px;
  background-color: var(--bg-primary);
  font-size: 13px;
  line-height: 17.64px;
  outline: none;
  border: 1px solid var(--border-color);
}

.payment-table-row {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid var(--border-color);
  padding: 6px 3px 6px 0px;
  align-items: center;
}

.invoice-table-container {
  border-radius: 4px 4px 0px 0px;
  /* max-width: 1100px; */
  padding: 16px 24px;
  border-radius: 8px;
  /* border: 1px solid var(--border-color); */
}

/* Invoice Table */
.invoice-sub-table-container {
  border-radius: 4px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  border: 1px solid var(--border-color);
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table-header th {
  font-weight: 600;
  padding: 6px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.invoice-table-row td {
  padding: 5px;
  border-bottom: 1px solid var(--border-color);
}

.invoice-table-footer td {
  padding: 5px;
  border-top: 1px solid var(--border-color);
  font-weight: 600;
}

.invoice-footer-value {
  text-align: center;
}

/* Styling for total row */
.invoice-table-footer.total {
  font-weight: bold;
}

.invoice-table-footer {
  padding: 3px 5px 5px 0px;
  display: flex;
}

.invoice-footer-label {
  flex: 4;
  text-align: right;
  /* padding-right: 20px; */
}

.invoice-footer-value {
  flex: 1;
  text-align: center;
}

/* Invoice Table */

.invoice-txt {
  font-size: 12px;
}

.invoice-line {
  background-color: var(--border-color);
  height: 1px;
  width: 100%;
}

.print-btn {
  background-color: black;
  color: white;
  border-radius: 4px;
  padding: 1px 7px 1px 7px;
  cursor: pointer;
}

.currency-indicator {
  border-right: 1px solid var(--border-color);
  padding-right: 10px;
}
.currency-indicator-input {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 0 7px;
  display: flex;
  align-items: center;
}
.invoice-editor-outer {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 2px;
}

.generate-bill-icon {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 5px 10px;
  color: var(--second-text-color);
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.generate-bill-icon:hover {
  background-color: #fff;
}
.task-count-bill-details-container {
  display: flex;
  padding: 10px 0px;
  color: var(--second-text-color);
  background-color: var(--bg-light);
  border-radius: var(--border-radius);
}

.task-count-bill-details {
  padding: 0 10px;
  border-right: 1px solid var(--second-text-color);
  display: flex;
  gap: 15px;
}

.common-separation {
  border-bottom: 1px solid var(--border-color);
}

.invoice-sub {
  display: flex;
  padding: 8px 0px 8px 0px;
  justify-content: space-between;
  /* width: 50vw; */
  /* border-bottom: 1px solid var(--border-color); */
  margin-bottom: 15px;
  align-items: baseline;
}

.payment-total {
  border-top: 1px solid #f2f2f2;
}

.export_text {
  padding: 6px 6px;
  /* margin-bottom: 10px; */
  font-weight: 400;
  width: 100%;
  color: var(--btn-text-color);
  font-size: 14px;
  height: 40px;
  outline: none;
  border: none;
  /* background: var(--bg-light-gray); */
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.export-inp-container {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 19px;
}
.inp-head {
  padding: 7px 3px 7px 7px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  width: 100%;
}

.export-options-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.export-option {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.icon {
  color: #007bff;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  /* background: red; */
  height: 92%;
  justify-content: space-between;
  /* flex: 1; */
}

/* .sml-crd {
  border: 1px solid var(--border-color);
  padding: 3px 4px;
} */

.sml-crd {
  cursor: 'pointer';
  display: flex;
  align-items: center;
  padding: 2px 6px;
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  /* transition: all 0.2s ease-in-out; */
}

.label-input {
  position: absolute;
  top: -10px;
  background: white;
  left: 7px;
  font-size: 12.5px;
  opacity: 0.5;
}
